import React, { useState, useEffect, useCallback } from 'react';

import PhotoAlbum from "react-photo-album";
import { Lightbox, ImageSlide, isImageSlide } from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export function ImageCollage(props) {
	const [loading, setLoading] = useState(true);
	const [albumImages] = useState([]);
	const [galleryImages] = useState([]);
	const [index, setIndex] = useState(-1);

	const search = useCallback(() => {
		if( 'images' in props && props.images !== undefined ) {
			for( var pos = 0; pos < props.images.length; pos++ ) {
				var image = props.images[pos];
				var credit = '';
				if( 'credit' in image ) {
					if( 'credit_url' in image && image['credit_url'] !== '' ) {
						credit = <a href={image.credit_url} target="_blank" rel="noopener noreferrer nofollow">{image.credit}</a>;
					} else {
						credit = <span>{image.credit}</span>;
					}
				}
				var description = '';
				if( 'caption' in image && 'credit' in image ) {
					description = <p>{image.caption}<br/>Credit: {credit}</p>
				} else if( 'caption' in image ) {
					description = <p>{image.caption}</p>
				} else if( 'credit' in image ) {
					description = <p>Credit: {credit}</p>
				}

				var original = 'https://s3-eu-west-1.amazonaws.com/sinatraology/' + props.type + '/' + image.filename;
				var thumb = 'https://s3-eu-west-1.amazonaws.com/sinatraology-thumbs/' + props.type + '/' + image.filename;

				let width = 200;
				let height = 200;
				if(props.type === 'scripts') {
					width = 150;
					height = 200;
				}
				albumImages.push( {
					src: thumb,
					description: description,
					width: width,
					height: height,
				});
				galleryImages.push( {
					'src': original,
					'thumbnail': thumb,
					'description': description,
				});
			}
			setLoading(false);
		}
	}, [galleryImages, albumImages, props]);

	useEffect(() => { if( loading ) { search() } }, [loading, search])

	if(galleryImages.length > 0) {
		return (
			<div className="image_collage">
				<PhotoAlbum
					layout="masonry"
					photos={albumImages}
					onClick={({ index: current }) => setIndex(current)}
				/>
				<Lightbox
					slides={galleryImages}
					plugins={[Captions, Fullscreen, Thumbnails, Zoom]}
					open={index >= 0}
					close={() => setIndex(-1)}
					index={index}
					render={{
						thumbnail: ({ slide, rect, render, imageFit }) =>
							isImageSlide(slide) && slide.thumbnail ? (
								<ImageSlide slide={{ src: slide.thumbnail }} rect={rect} render={render} imageFit={imageFit} />
							) : undefined,
					}}
				/>
			</div>
		);
	}
	return <i>No images available</i>;
}
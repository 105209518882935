import React, { useState, useEffect, useCallback } from 'react';

import { ImageCollage } from './ImageCollage';
import { Grid, Dropdown } from 'semantic-ui-react'
import _ from 'lodash'

export function Attachments(props) {
	const [loading, setLoading] = useState(true);
	const [images, setImages] = useState([]);
	const [titles, setTitles] = useState([]);
	const [countries, setCountries] = useState([]);
	const [types, setTypes] = useState([]);
	const [title, setTitle] = useState('');
	const [country, setCountry] = useState('');
	const [type, setType] = useState('');
	const [key, setKey] = useState(props.id);

	const filter = useCallback(() => {
		let imagesLocal = [];
		let titlesLocal = new Set();
		let countriesLocal = new Set();
		let typesLocal = new Set();
		if( 'images' in props && props.images !== undefined ) {
			for( var pos = 0; pos < props.images.length; pos++ ) {
				let image = props.images[pos];
				if(title !== '' && (!('title' in image) || image.title !== title)) {
					continue;
				}
				if(country !== '' && (!('country' in image) || image.country !== country)) {
					continue;
				}
				if(type !== '' && (!('type' in image) || image.type !== type)) {
					continue;
				}
				imagesLocal.push(image);
				if('title' in image) {
					titlesLocal.add(image.title);
				}
				if('country' in image) {
					countriesLocal.add(image.country);
				}
				if('type' in image) {
					typesLocal.add(image.type);
				}
			}
		}
		setTypes(_.map(Array.from(typesLocal), (type, index) => ({
			key: type,
			text: type,
			value: type,
		})));
		setTitles(_.map(Array.from(titlesLocal), (title, index) => ({
			key: title,
			text: title,
			value: title,
		})));
		setCountries(_.map(Array.from(countriesLocal), (country, index) => ({
			key: country,
			text: country,
			value: country,
		})));
		setImages(imagesLocal);
		setKey(props.id + '_' + title + '_' + type + '_' + country);
		setLoading(false);
	}, [props, title, type, country]);

	useEffect(() => { if( loading ) { filter() } }, [loading, filter])

	if(images.length > 0) {
		return (
			<div className="attachments">
				<Grid columns={12} stackable relaxed='very'>
					<Grid.Column width={1}>
					</Grid.Column>
					<Grid.Column width={3}>
						<Dropdown
							placeholder='Type'
							fluid
							search
							selection
							clearable
							options={types}
							value={type}
							onChange={(e, { value }) => {setType(value); setLoading(true);}}
						/>
					</Grid.Column>
					<Grid.Column width={3}>
						<Dropdown
							placeholder='Country'
							fluid
							search
							selection
							clearable
							options={countries}
							value={country}
							onChange={(e, { value }) => {setCountry(value); setLoading(true);}}
						/>
					</Grid.Column>
					<Grid.Column width={4}>
						<Dropdown
							placeholder='Title'
							fluid
							search
							selection
							clearable
							options={titles}
							value={title}
							onChange={(e, { value }) => {setTitle(value); setLoading(true);}}
						/>
					</Grid.Column>
				</Grid>
				<ImageCollage images={images} type={props.type} key={key}/>
			</div>
		);
	}
	return <i>No images available</i>;
}
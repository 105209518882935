import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API_ADDRESS } from './../../constants.js';
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import useUrlState from '@ahooksjs/use-url-state';
import _ from 'lodash'
import { Icon, Table, Dimmer, Loader, Segment, Button, Grid, Pagination, Input, Header, Divider, Dropdown } from 'semantic-ui-react'

import { ImageCollage } from './../subcomponents/ImageCollage';
import { ReferencesPopup } from './../subcomponents/ReferencesPopup';
import { RecordingTakeReleases } from './../subcomponents/RecordingTakeReleases';
import { SessionPersonnel } from './../subcomponents/SessionPersonnel';

export function AdvertBrowseCommon(props) {
	const navigate = useNavigate();
	const {id} = useParams();
	const ref = React.useRef(null);

	const [firstLoad, setFirstLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [resetPage, setResetPage] = useState(false);
	const [changePage, setChangePage] = useState(false);
	const [searching, setSearching] = useState(false);
	const [rows, setRows] = useState([]);
	const [rowsToOpen, setRowsToOpen] = useState([]);
	const [first, setFirst] = useState(0);
	const [last, setLast] = useState(0);

	let stateTmp = {
		page: 1,
		sort: "date",
		query: "",
	};

	const [numberOfRows] = useState(50);
	const [urlState, setUrlState] = useUrlState(stateTmp,
		{
			parseOptions: {
				arrayFormat: 'bracket',
			},
			stringifyOptions: {
				arrayFormat: 'bracket',
			},
		}
	);
	let stateToUse = props.urlParams ? urlState : stateTmp;
	const [state, setState] = useState(stateToUse);

	const [queryTmp, setQueryTmp] = useState(state.query);
	const [pages, setPages] = useState(state.page);
	const [sortByDate, setSortByDate] = useState(state.sort === 'date');
	const [sortByTitle, setSortByTitle] = useState(state.sort === 'title');

	const [types, setTypes] = useState([]);
	const typeOptions = _.map(types, (type, index) => ({
		key: type['name'],
		text: type['name'] + ' (' + type['count'] + ')',
		value: type['name'],
	}));

	const stateHelper = useCallback((stateTmp) => {
		let stateToSet = {};
		if(stateTmp.page !== undefined && Number(state.page) !== Number(stateTmp.page)) {
			stateToSet['page'] = Number(stateTmp.page);
		}
		for(const key in stateTmp) {
			if(key === 'page') { continue; }
			if(stateTmp[key] !== undefined && state[key] !== stateTmp[key]) {
				stateToSet[key] = stateTmp[key];
			}
		}
		if(Object.keys(stateToSet).length > 0) {
			setState(prevState => ({...prevState, ...stateToSet}));
			if(props.urlParams) {
				setUrlState(prevState => ({...prevState, ...stateToSet}));
			}
		}
	}, [state, setState, props.urlParams, setUrlState]);

	const search = useCallback(() => {
		let pagetmp = state.page;
		if( resetPage || Number(pagetmp) === 0 ) {
			pagetmp = 1;
		}
	
		var urlRoot = `${API_ADDRESS}/advert/browse`;
		var url = `?page=${pagetmp}`;
		var urlAttachment = '&sort=' + state.sort + '&limit=' + numberOfRows;
		if(state.query) {
			urlAttachment += '&query=' + encodeURIComponent(state.query);
		}
		if(state.type) {
			state.type.forEach(function(filter) {
				urlAttachment += '&type=' + encodeURIComponent(filter);
			});
		}
		if(props.organisation) {
			urlAttachment += '&organisation=' + props.organisation;
		}

		if(props.urlParams) {
			if(id) {
				navigate('/advert');
			}
		}

		if(id) {
			if(!isNaN(id)) {
				let new_id = "advert_id_" + id;
				urlAttachment += '&advert=' + new_id;
				setRowsToOpen([...rowsToOpen, new_id]);
			} else {
				urlAttachment += '&advert=' + id;
				setRowsToOpen([...rowsToOpen, id]);
			}
		}

		fetch(urlRoot + url + urlAttachment)
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				setTotal(json.count);
				setResetPage(false);
				stateHelper({page: json.page});
				setPages(json.pages);
				setLoading(false);
				setChangePage(false);

				var rows = json.adverts;
				setRows(rows);

				var first_tmp = (json.page-1)*numberOfRows+1;
				var last_tmp = (json.page)*numberOfRows;
				if( first_tmp < 0 ) {
					first_tmp = 0;
				}
				if( first_tmp > json.count ) {
					first_tmp = 0;
					last_tmp = 0;
				}
				if( last_tmp > json.count ) {
					last_tmp = json.count;
				}
				setFirst(first_tmp);
				setLast(last_tmp);

				setTypes(json.types);

				setSearching(false);
				if(!firstLoad) {
					ref.current.scrollIntoView({behavior: 'smooth'});
				}

				if(firstLoad && rows.length === 0 && props.relatedRendered) {
					props.relatedRendered(false);
				}
				setFirstLoad(false);
			})
			.catch(function(ex) {
				setSearching(false);
				console.log(ex);
				// navigate('/500');
			})
	}, [numberOfRows, state, stateHelper, resetPage, firstLoad, id, props, navigate, rowsToOpen]);	

	const handlePaginationClick = (e, { activePage }) => stateHelper({page: activePage});
	const changeSortField = (field) => stateHelper({sort:field});

	useEffect(() => { 
		if(props.urlParams) {
			let stateToSet = {};
			for(const key in urlState) {
				if(key === 'page') {
					if(urlState.page !== undefined && Number(state.page) !== Number(urlState.page)) {
						stateToSet['page'] = Number(urlState.page);
					}
				} else {
					if(urlState[key] !== undefined && state[key] !== urlState[key]) {
						stateToSet[key] = urlState[key];
					}
				}
			}
			if(Object.keys(stateToSet).length > 0) {
				setState(prevState => ({...prevState, ...stateToSet}));
				if(stateToSet.query) {
					setQueryTmp(stateToSet.query);
					setLoading(true);
				}
			}
		}
	}, [urlState, state, props.urlParams]);

	useEffect(() => { if( loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])
	useEffect(() => { if( changePage ) { setLoading(true); } }, [changePage])
	useEffect(() => { setChangePage(true) }, [state.page, state.sort])
	useEffect(() => {
		if(state.sort === 'title') {
			setSortByTitle(true);
			setSortByDate(false);
		} else {
			setSortByTitle(false);
			setSortByDate(true);
		}
		setResetPage(true);
		setChangePage(true);
	}, [state.sort])

	return (
		<div ref={ref}>
			<Segment inverted className="browse-table-segment">
				<Dimmer active={loading}>
					<Loader size='large'>Loading</Loader>
				</Dimmer>
				{!props.slim &&
					<Grid columns={3} stackable relaxed='very' className="width100">
						<Grid.Column>
							<Button.Group>
								<Button positive={sortByTitle} onClick={() => changeSortField('title')}>Sort by Title</Button>
								<Button.Or />
								<Button positive={sortByDate} onClick={() => changeSortField('date')}>Sort by Year</Button>
							</Button.Group>
						</Grid.Column>
						<Grid.Column>
							<Input inverted fluid
								action={{ icon: 'search', onClick: (e) => {setResetPage(true); setChangePage(true);} }}  
								placeholder='Search...'
								value={queryTmp}
								onChange={(e, { value }) => setQueryTmp(value)}
								onBlur={(e) => stateHelper({query:queryTmp})}
								onKeyUp={(e) => {if (e.key === "Enter") {stateHelper({query:queryTmp}); setResetPage(true); setChangePage(true);}}}
							/>
						</Grid.Column>
						<Grid.Column>
							<Dropdown
								placeholder='Type'
								fluid
								multiple
								search
								selection
								options={typeOptions}
								value={state.type}
								onChange={(e, { value }) => {stateHelper({"type": value}); setResetPage(true); setChangePage(true);}}
							/>
						</Grid.Column>
					</Grid>
				}
				<Table celled inverted selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Title</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>Year</Table.HeaderCell>
							<Table.HeaderCell>Country</Table.HeaderCell>
							<Table.HeaderCell>View</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{rows.length === 0 && 
								<Table.Row>
									<Table.Cell colSpan={8}>
										<Segment placeholder inverted>
											<Header icon inverted>
												<Icon name='search' />
												No adverts match your query
											</Header>
										</Segment>
									</Table.Cell>
								</Table.Row>
						}
						{rows.map(function(advert){
							var json_obj = {};
							let organisations = [];
							let studios = [];
							let films = [];
							if(rowsToOpen.includes(advert.id)) {
								try {
									json_obj = JSON.parse(advert.json[0]);
									console.log(json_obj);
									for(var organisationId in json_obj['organisations']) {
										let organisation = json_obj['organisations'][organisationId];
										organisations.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										organisations.push(<br key={'br_' + organisation._id}/>);
									}
									for(organisationId in json_obj['studios']) {
										let organisation = json_obj['studios'][organisationId];
										studios.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										studios.push(<br key={'br_' + organisation._id}/>);
									}
									for(organisationId in json_obj['sponsor']) {
										let organisation = json_obj['sponsor'][organisationId];
										studios.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										studios.push(<br key={'br_' + organisation._id}/>);
									}
									for(organisationId in json_obj['network']) {
										let organisation = json_obj['network'][organisationId];
										organisations.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										organisations.push(<br key={'br_' + organisation._id}/>);
									}
									if('film' in json_obj) {
										for(var filmId in json_obj['film']) {
											let film = json_obj['film'][filmId];
											films.push(<Link to={'/film/' + film._id} key={film._id}>{film.title}</Link>);
											films.push(<br key={'br_' + film._id}/>);
										}
									}
								} catch(ex) {
									console.log(ex);
								}
							}
							var row_link = '/advert/' + advert.id;
							return(
								<Fragment key={advert.id}>
									<Table.Row key={advert.id} onClick={() => 
									{
										if(rowsToOpen.includes(advert.id)) {
											setRowsToOpen(rowsToOpen.filter(id => id !== advert.id));
										} else {
											setRowsToOpen([...rowsToOpen, advert.id]);
										}
									}} className='browse-table-row'>
										<Table.Cell>{advert.title}</Table.Cell>
										<Table.Cell>{advert.type}</Table.Cell>
										<Table.Cell>{advert.date_display}</Table.Cell>
										<Table.Cell>{advert.country}</Table.Cell>
										<Table.Cell>
											{rowsToOpen.includes(advert.id) && <Icon name='angle up'/>}
											{!rowsToOpen.includes(advert.id) && <Icon name='angle down'/>}
										</Table.Cell>
									</Table.Row>
									{rowsToOpen.includes(advert.id) &&
										<Table.Row key={advert.id + '_open'}>
											<Table.Cell className="merchandise_open" colSpan={6}>
												<Grid>
													<Grid.Row style={{margin:'10px'}}>
														<Grid.Column width={7} floated='left'>
															<Divider horizontal inverted>
																<Header as='h4' inverted>
																	<Icon name='info circle' />
																	Details
																</Header>
															</Divider>
															<Table definition inverted>
																<Table.Body>
																	{'title' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Title</Table.HeaderCell>
																			<Table.Cell>{json_obj.title}</Table.Cell> 
																		</Table.Row>
																	}
																	{'date_display' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Date</Table.HeaderCell>
																			<Table.Cell>{json_obj.date_display}</Table.Cell> 
																		</Table.Row>
																	}
																	{'country' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Country</Table.HeaderCell>
																			<Table.Cell>{json_obj.country}</Table.Cell> 
																		</Table.Row>
																	}
																	{'type' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Type</Table.HeaderCell>
																			<Table.Cell>{json_obj.type}</Table.Cell> 
																		</Table.Row>
																	}
																	{('_type' in json_obj && json_obj._type === 'film') &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Film</Table.HeaderCell>
																			<Table.Cell><Link to={'/film/' + json_obj._id}>{json_obj._name}</Link></Table.Cell> 
																		</Table.Row>
																	}
																	{films.length > 0 &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Film</Table.HeaderCell>
																			<Table.Cell>{films}</Table.Cell>
																		</Table.Row>
																	}
																	{('_type' in json_obj && json_obj._type === 'television') &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Television</Table.HeaderCell>
																			<Table.Cell><Link to={'/television/' + json_obj._id}>{json_obj._name}</Link></Table.Cell> 
																		</Table.Row>
																	}
																	{('_type' in json_obj && json_obj._type === 'album') &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Album</Table.HeaderCell>
																			<Table.Cell><Link to={'/album/' + json_obj._id}>{json_obj._name}</Link></Table.Cell> 
																		</Table.Row>
																	}
																	{('network' in json_obj && organisations.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Network</Table.HeaderCell>
																			<Table.Cell>{organisations}</Table.Cell>
																		</Table.Row>
																	}
																	{('sponsor' in json_obj && studios.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Sponsor</Table.HeaderCell>
																			<Table.Cell>{studios}</Table.Cell>
																		</Table.Row>
																	}
																	{('organisations' in json_obj && organisations.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Organisations</Table.HeaderCell>
																			<Table.Cell>{organisations}</Table.Cell>
																		</Table.Row>
																	}
																	{('studios' in json_obj && studios.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Studios</Table.HeaderCell>
																			<Table.Cell>{studios}</Table.Cell>
																		</Table.Row>
																	}
																</Table.Body>
															</Table>
															{'notes' in json_obj && 
																<>
																	<h6>Notes</h6>
																	<p dangerouslySetInnerHTML={{__html: json_obj.notes}}></p>
																</>
															}
														</Grid.Column>
														<Grid.Column width={7} floated='right'>
															{('people' in json_obj && Object.keys(json_obj.people).length > 0) &&
																<>
																	<Divider horizontal inverted>
																		<Header as='h4' inverted>
																			<Icon name='male' />
																			People
																		</Header>
																	</Divider>
																	<SessionPersonnel people={json_obj.people} sessions={[]} session={false}/>
																</>
															}		
														</Grid.Column>
													</Grid.Row>
													<Grid.Row>
														<Grid.Column floated='left'>
															{('images' in json_obj && json_obj.images.length > 0) &&
																<ImageCollage images={json_obj.images} type="promos"/>
															}
														</Grid.Column>
													</Grid.Row>
													{('soundtrack' in json_obj && json_obj.soundtrack.length > 0) &&
														<Grid.Row style={{marginLeft:'20px', marginRight: '20px'}}>
															<Divider horizontal inverted>
																<Header as='h4' inverted>
																	<Icon name='list ol' />
																	Soundtrack
																</Header>
															</Divider>
															<Table definition inverted>
																<Table.Body>
																	<Table.Row>
																		<Table.HeaderCell width={2}>Position</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Name</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Length</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Recording</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Take</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Variant / Excerpt</Table.HeaderCell>
																	</Table.Row>
																	{json_obj.soundtrack.map(function(track, i) {
																		let recording = '';
																		let take = '';
																		let variant = '';
																		if('recording' in track) {
																			for(var pos in track.recording.sessions) {
																				if(recording !== '') { recording += '\n'; }
																				let session = track.recording.sessions[pos];
																				recording += session.obj.date_display;
																			}
																			recording = <Link to={'/recording/' + track.recording._id}>{recording}</Link>;
																		}
																		if('take' in track) {
																			take = <Link to={'/recording/' + track.recording._id}>{track.take.name}</Link>;
																		}
																		if('variant' in track) {
																			variant = <span>{track.variant.name}</span>;
																		}

																		if('excerpt' in track || 'excerpt-unknown' in track) {
																			let excerpt = '';
																			if('excerpt' in track) {
																				excerpt = 'Excerpt details: ' + track.excerpt;
																			} else {
																				excerpt = 'Excerpt details: unknown';
																			}
																			if(variant) {
																				variant = <>{variant}<br/><br/><span>{excerpt}</span></>;
																			} else {
																				variant = <span>{excerpt}</span>;
																			}
																		}
																		return <Table.Row key={track.position}>
																			<Table.Cell>{i+1}</Table.Cell>
																			<Table.Cell>{track.song.title}</Table.Cell> 
																			<Table.Cell>{track.length}</Table.Cell> 
																			<Table.Cell>{recording}</Table.Cell>
																			<Table.Cell>{take}</Table.Cell> 
																			<Table.Cell>{variant}</Table.Cell> 
																		</Table.Row>
																	})}
																</Table.Body>
															</Table>
														</Grid.Row>
													}
													{('variants' in json_obj && json_obj.variants.length > 0) &&
														<Grid.Row>
															<Divider horizontal inverted>
																<Header as='h4' inverted>
																	{(json_obj.type !== 'radio' && json_obj.type !== 'radio-spot') && 
																		<Icon name='file video' />
																	}
																	{(json_obj.type === 'radio' && json_obj.type === 'radio-spot') && 
																		<Icon name='file audio' />
																	}
																	Variants 
																</Header>
															</Divider>
															<Table inverted>
																<Table.Body>
																	<Table.Row>
																		<Table.HeaderCell>Name</Table.HeaderCell>
																		<Table.HeaderCell>Types</Table.HeaderCell>
																		<Table.HeaderCell>Length</Table.HeaderCell>
																		<Table.HeaderCell>Notes</Table.HeaderCell>
																		<Table.HeaderCell></Table.HeaderCell>
																	</Table.Row>
																	{json_obj.variants.map(function(variant){
																		return <Table.Row key={variant._id}>
																			<Table.Cell>{variant.name}</Table.Cell>
																			<Table.Cell>{variant.type}</Table.Cell>
																			<Table.Cell>{variant.length}</Table.Cell>
																			<Table.Cell>{variant.notes}</Table.Cell>
																			<Table.Cell>
																				{'recording-audio' in variant && <Icon name='sound' title='Audio recording in-circulation'/>}
																				{'recording-video' in variant && <Icon name='video' title='Video recording in-circulation'/>}
																				{'sinatraology_collection' in variant && <Icon name='check square outline' title='In the Sinatraology Collection'/>}
																			</Table.Cell>
																		</Table.Row>
																	})}
																</Table.Body>
															</Table>
															<RecordingTakeReleases key={json_obj._id} advert={json_obj._id} advert_variants={json_obj.variants}/>
														</Grid.Row>
													}
													<Grid.Row>
														<Grid.Column width={2} floated='right' style={{ textAlign: 'right' }}>
															{('references' in json_obj && json_obj.references.length > 0) &&
																<ReferencesPopup references={json_obj.references}/>
															}
															<a href={row_link}><Icon name='linkify'/></a>
														</Grid.Column>
													</Grid.Row>
												</Grid>
											</Table.Cell>
										</Table.Row>
									}
								</Fragment>
							);
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell>
								Showing {first}-{last} of {total} adverts.
							</Table.HeaderCell>
							<Table.HeaderCell colSpan='7' textAlign='right'>
								<Pagination activePage={state.page} totalPages={pages} firstItem={null} lastItem={null} onPageChange={handlePaginationClick} siblingRange={1} inverted/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</div>
	);
}
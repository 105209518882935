import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API_ADDRESS } from './../../constants.js';
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import useUrlState from '@ahooksjs/use-url-state';

import { Icon, Table, Dimmer, Loader, Segment, Button, Grid, Divider, Pagination, Input, Header } from 'semantic-ui-react'

import { SongRecordings } from './../subcomponents/SongRecordings';
import { SongSheetMusic } from './../subcomponents/SongSheetMusic';
import { ReferencesPopup } from './../subcomponents/ReferencesPopup';

export function SongBrowseCommon(props) {
	const navigate = useNavigate();
	const {id} = useParams();
	const [idUpdated, setIdUpdated] = useState(id);
	useEffect(() => { if( idUpdated !== id ) { setIdUpdated(id); setLoading(true); } }, [idUpdated, id])
	const ref = React.useRef(null);

	const [firstLoad, setFirstLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [resetPage, setResetPage] = useState(false);
	const [changePage, setChangePage] = useState(false);
	const [searching, setSearching] = useState(false);
	const [rows, setRows] = useState([]);
	const [rowsToOpen, setRowsToOpen] = useState([]);
	const [first, setFirst] = useState(0);
	const [last, setLast] = useState(0);

	let stateTmp = {
		page: 1,
		sort: "title",
		query: "",
	};

	const [numberOfRows] = useState(50);
	const [urlState, setUrlState] = useUrlState(stateTmp,
		{
			parseOptions: {
				arrayFormat: 'bracket',
			},
			stringifyOptions: {
				arrayFormat: 'bracket',
			},
		}
	);
	let stateToUse = props.urlParams ? urlState : stateTmp;
	const [state, setState] = useState(stateToUse);

	const [queryTmp, setQueryTmp] = useState(state.query);
	const [pages, setPages] = useState(state.page);
	const [sortByYear, setSortByYear] = useState(state.sort === 'year');
	const [sortByTitle, setSortByTitle] = useState(state.sort === 'title');

	const stateHelper = useCallback((stateTmp) => {
		let stateToSet = {};
		if(stateTmp.page !== undefined && Number(state.page) !== Number(stateTmp.page)) {
			stateToSet['page'] = Number(stateTmp.page);
		}
		for(const key in stateTmp) {
			if(key === 'page') { continue; }
			if(stateTmp[key] !== undefined && state[key] !== stateTmp[key]) {
				stateToSet[key] = stateTmp[key];
			}
		}
		if(Object.keys(stateToSet).length > 0) {
			setState(prevState => ({...prevState, ...stateToSet}));
			if(props.urlParams) {
				setUrlState(prevState => ({...prevState, ...stateToSet}));
			}
		}
	}, [state, setState, props.urlParams, setUrlState]);
	
	const search = useCallback(() => {
		let pagetmp = state.page;
		if( resetPage || Number(pagetmp) === 0 ) {
			pagetmp = 1;
		}
	
		var urlRoot = `${API_ADDRESS}/song/browse`;
		var url = `?page=${pagetmp}`;
		var urlAttachment = '&sort=' + state.sort + '&limit=' + numberOfRows;
		if(state.query) {
			urlAttachment += '&query=' + encodeURIComponent(state.query);
		}
		if(props.urlParams) {
			if(idUpdated) {
				navigate('/song');
			}
		}

		if(props.person) {
			urlAttachment += '&person=' + encodeURIComponent(props.person);
		}

		if(idUpdated) {
			urlAttachment += '&song=' + idUpdated;
			setRowsToOpen([...rowsToOpen, idUpdated]);			
		}

		fetch(urlRoot + url + urlAttachment)
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				setTotal(json.count);
				setResetPage(false);
				stateHelper({page: json.page});
				setPages(json.pages);
				setLoading(false);
				setChangePage(false);

				var rows = json.song;
				setRows(rows);

				var first_tmp = (json.page-1)*numberOfRows+1;
				var last_tmp = (json.page)*numberOfRows;
				if( first_tmp < 0 ) {
					first_tmp = 0;
				}
				if( first_tmp > json.count ) {
					first_tmp = 0;
					last_tmp = 0;
				}
				if( last_tmp > json.count ) {
					last_tmp = json.count;
				}
				setFirst(first_tmp);
				setLast(last_tmp);

				setSearching(false);
				if(!firstLoad) {
					ref.current.scrollIntoView({behavior: 'smooth'});
				}

				if(firstLoad && rows.length === 0 && props.relatedRendered) {
					props.relatedRendered(false);
				}
				setFirstLoad(false);
			})
			.catch(function(ex) {
				setSearching(false);
				console.log(ex);
				// navigate('/500');
			})
	}, [numberOfRows, state, stateHelper, resetPage, navigate, firstLoad, idUpdated, props, rowsToOpen]);	

	const handlePaginationClick = (e, { activePage }) => stateHelper({page: activePage});
	const changeSortField = (field) => stateHelper({sort:field});

	useEffect(() => { 
		if(props.urlParams) {
			let stateToSet = {};
			for(const key in urlState) {
				if(key === 'page') {
					if(urlState.page !== undefined && Number(state.page) !== Number(urlState.page)) {
						stateToSet['page'] = Number(urlState.page);
					}
				} else {
					if(urlState[key] !== undefined && state[key] !== urlState[key]) {
						stateToSet[key] = urlState[key];
					}
				}
			}
			if(Object.keys(stateToSet).length > 0) {
				setState(prevState => ({...prevState, ...stateToSet}));
				if(stateToSet.query) {
					setQueryTmp(stateToSet.query);
					setLoading(true);
				}
			}
		}
	}, [urlState, state, props.urlParams]);

	useEffect(() => { if( loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])
	useEffect(() => { if( changePage ) { setLoading(true); } }, [changePage])
	useEffect(() => { setChangePage(true) }, [state.page, state.sort])
	useEffect(() => {
		if(state.sort === 'title') {
			setSortByTitle(true);
			setSortByYear(false);
		} else {
			setSortByTitle(false);
			setSortByYear(true);
		}
		setResetPage(true);
		setChangePage(true);
	}, [state.sort])

	return (
		<div ref={ref}>
			<Segment inverted className="browse-table-segment">
				<Dimmer active={loading}>
					<Loader size='large'>Loading</Loader>
				</Dimmer>
				<Grid columns={3} stackable relaxed='very' className="width100">
					<Grid.Column>
						<Button.Group>
							<Button positive={sortByTitle} onClick={() => changeSortField('title')}>Sort by Title</Button>
							<Button.Or />
							<Button positive={sortByYear} onClick={() => changeSortField('year')}>Sort by Year</Button>
						</Button.Group>
					</Grid.Column>
					<Grid.Column>
						<Input inverted fluid
							action={{ icon: 'search', onClick: (e) => {setResetPage(true); setChangePage(true);} }}  
							placeholder='Search...'
							value={queryTmp}
							onChange={(e, { value }) => setQueryTmp(value)}
							onBlur={(e) => stateHelper({query:queryTmp})}
							onKeyUp={(e) => {if (e.key === "Enter") {stateHelper({query:queryTmp}); setResetPage(true); setChangePage(true);}}}
						/>
					</Grid.Column>
				</Grid>
				<Table celled inverted selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Title</Table.HeaderCell>
							<Table.HeaderCell>Year</Table.HeaderCell>
							<Table.HeaderCell>Writers</Table.HeaderCell>
							<Table.HeaderCell>View</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{rows.length === 0 && 
								<Table.Row>
									<Table.Cell colSpan={8}>
										<Segment placeholder inverted>
											<Header icon inverted>
												<Icon name='search' />
												No songs matches your query
											</Header>
										</Segment>
									</Table.Cell>
								</Table.Row>
						}
						{rows.map(function(song){
							var json_obj = {};
							var titles = [];
							if(rowsToOpen.includes(song.id)) {
								try {
									json_obj = JSON.parse(song.json[0]);
									console.log(json_obj);
									for(var titlePos in json_obj['titles']) {
										let title = json_obj['titles'][titlePos];
										titles.push(<span key={'span_title_' + titlePos}>{title.title} ({title.type})</span>);
										titles.push(<br key={'hr_title_' + titlePos}/>);
									}
								} catch(ex) {
									console.log(ex);
								}
							}
							var row_link = '/song/' + song.id;
							return(
								<Fragment key={song.id}>
									<Table.Row key={song.id} onClick={() => 
									{
										if(rowsToOpen.includes(song.id)) {
											setRowsToOpen(rowsToOpen.filter(id => id !== song.id));
										} else {
											setRowsToOpen([...rowsToOpen, song.id]);
										}
									}} className='browse-table-row'>
										<Table.Cell>{song.title}</Table.Cell>
										<Table.Cell>{song.year}</Table.Cell>
										<Table.Cell>{song.people && song.people.join(', ')}</Table.Cell>
										<Table.Cell>
											{rowsToOpen.includes(song.id) && <Icon name='angle up'/>}
											{!rowsToOpen.includes(song.id) && <Icon name='angle down'/>}
										</Table.Cell>
									</Table.Row>
									{rowsToOpen.includes(song.id) &&
										<Table.Row key={song.id + '_open'}>
											<Table.Cell className="merchandise_open" colSpan={6}>
												<Grid>
													<Grid.Column width={7} floated='right'>
														<Divider horizontal inverted>
															<Header as='h4' inverted>
																<Icon name='info circle' />
																Details
															</Header>
														</Divider>
														<Table definition inverted>
															<Table.Body>
																<Table.Row>
																	<Table.HeaderCell width={2}>Title</Table.HeaderCell>
																	<Table.Cell>{json_obj.title}</Table.Cell> 
																</Table.Row>
																{(titles && titles.length > 0) &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Alternate titles</Table.HeaderCell>
																		<Table.Cell>{titles}</Table.Cell> 
																	</Table.Row>
																}
																{('source_str' in json_obj && json_obj.source_str !== '') && 
																	<Table.Row>
																		<Table.HeaderCell width={2}>Source</Table.HeaderCell>
																		<Table.Cell><p dangerouslySetInnerHTML={{__html: json_obj.source_str}}></p></Table.Cell>
																	</Table.Row>
																}
															</Table.Body>
														</Table>
														{'notes' in json_obj && 
															<>
																<h6>Notes</h6>
																<p dangerouslySetInnerHTML={{__html: json_obj.notes}}></p>
															</>
														}
														{('in-medley' in json_obj && json_obj['in-medley'].length > 0) && 
															<>
																<Divider horizontal inverted>
																	<Header as='h4' inverted>
																		<Icon name='music' />
																		Medleys:
																	</Header>
																</Divider>
															<Table definition inverted>
																<Table.Body>
																	<>
																		{json_obj['in-medley'].map(function(song, mi) {
																			let cell2 = '';
																			if('parody' in song) {
																				cell2 += '(parody)';
																			}
																			if('sample' in song) {
																				cell2 += '(sample)';
																			}
																			return <Table.Row key={'in_medley_' + mi}>
																				<Table.HeaderCell><Link to={'/song/' + song._id}>{song.title}</Link></Table.HeaderCell>
																				<Table.Cell>{cell2}</Table.Cell>
																			</Table.Row>;
																		})}
																	</>
																</Table.Body>
															</Table>
														</>
													}
													</Grid.Column>
													<Grid.Column width={7} floated='left'>
														<Divider horizontal inverted>
															<Header as='h4' inverted>
																<Icon name='male' />
																Credits
															</Header>
														</Divider>
														<Table definition inverted>
															<Table.Body>
															{('medley' in json_obj && json_obj.medley.length > 0) && 
																<>
																	<Table.Row>
																		<Table.HeaderCell colSpan="2">
																			<b>Medley of</b>
																		</Table.HeaderCell>
																	</Table.Row>
																	{json_obj.medley.map(function(song, mi) {
																		let title = song.title;
																		if('parody' in song) {
																			title += ' (parody)';
																		}
																		if('sample' in song) {
																			title += ' (sample)';
																		}
																		return <Table.Row key={'medley_' + mi}>
																			<Table.HeaderCell>&#8627; {mi+1}</Table.HeaderCell>
																			<Table.Cell><Link to={'/song/' + song._id}>{title}</Link></Table.Cell>
																		</Table.Row>;
																	})}
																</>
															}
															{'people' in json_obj &&
																<>
																	{Object.keys(json_obj.people).map(function(role, i) {
																		return (
																			<Fragment key={'role_'+i}>
																				<Table.Row>
																					<Table.HeaderCell rowSpan={json_obj.people[role].length}>{role}</Table.HeaderCell>
																					<Table.Cell><Link to={'/person/' + json_obj.people[role][0]._id}>{json_obj.people[role][0].name}</Link></Table.Cell>
																				</Table.Row>
																				{json_obj.people[role].map(function(person, i) {
																					if(i === 0) { return <Fragment key={'person_' + i}></Fragment>; }
																					return <Table.Row key={'person_' + i}>
																						<Table.Cell><Link to={'/person/' + person._id}>{person.name}</Link></Table.Cell>
																					</Table.Row>
																				})}
																			</Fragment>
																		)
																	})}
																</>
															}
															</Table.Body>
														</Table>
													</Grid.Column>

													<Grid.Row>
														<SongRecordings song={song.id}/>
													</Grid.Row>

													<Grid.Row>
														<SongSheetMusic song={song.id}/>
													</Grid.Row>

													<Grid.Column width={3} floated='right' style={{ textAlign: 'right' }}>
														{('references' in json_obj && json_obj.references.length > 0) &&
															<ReferencesPopup references={json_obj.references}/>
														}
														<a href={row_link}><Icon name='linkify'/></a>
													</Grid.Column>
												</Grid>
											</Table.Cell>
										</Table.Row>
									}
								</Fragment>
							);
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell>
								Showing {first}-{last} of {total} songs.
							</Table.HeaderCell>
							<Table.HeaderCell colSpan='7' textAlign='right'>
								<Pagination activePage={state.page} totalPages={pages} firstItem={null} lastItem={null} onPageChange={handlePaginationClick} siblingRange={1} inverted/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</div>
	);
}
import React, { useState } from 'react';
import {References} from './References';
import {Modal, Header} from 'semantic-ui-react'

export function ReferencesPopup(props) {
	const [show, setShow] = useState(false);

	if( (!props.references || props.references.length === 0) && props.radio_listings === undefined ) {
		return '';
	}

	return (
		<Modal
			closeIcon={{ style: { top: '1.0535rem', right: '1rem' }, color: 'grey', name: 'close' }}
			size="large"
			open={show}
			className="alert modal_references"
			trigger={<button className='btn-link'>View references</button>}
			onClose={() => setShow(false)}
			onOpen={() => setShow(true)}
		>
			<Header icon="book" content="References"/>
			<Modal.Content>
				<References references={props.references} notes={props.notes} radio_listings={props.radio_listings} skip_divider={true}/>
			</Modal.Content>
		</Modal>
	);
}
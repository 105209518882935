import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API_ADDRESS } from './../../constants.js';
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import useUrlState from '@ahooksjs/use-url-state';
import _ from 'lodash'
import { Icon, Table, Dimmer, Loader, Segment, Grid, Divider, Pagination, Input, Header, Dropdown } from 'semantic-ui-react'

import { ImageCollage } from './../subcomponents/ImageCollage';
import { Attachments } from './../subcomponents/Attachments';
import { ReferencesPopup } from './../subcomponents/ReferencesPopup';
import { RecordingBrowseCommon } from './../browse/RecordingBrowseCommon';
import { RecordingTakeReleases } from './../subcomponents/RecordingTakeReleases';
import { EventPublications } from './../subcomponents/EventPublications';
import { EventMerchandise } from './../subcomponents/EventMerchandise';
import { SessionPersonnel } from './../subcomponents/SessionPersonnel';
import { Companies } from './../subcomponents/Companies';
import { ReleaseBrowseCommon } from './../browse/ReleaseBrowseCommon';

export function YearByYearCommon(props) {
	const navigate = useNavigate();
	const {id, event_id, radio_id, television_id, film_id, photo_id, album_id} = useParams();
	const ref = React.useRef(null);

	const [firstLoad, setFirstLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [resetPage, setResetPage] = useState(false);
	const [changePage, setChangePage] = useState(false);
	const [searching, setSearching] = useState(false);
	const [rows, setRows] = useState([]);
	const [rowsToOpen, setRowsToOpen] = useState([]);
	const [first, setFirst] = useState(0);
	const [last, setLast] = useState(0);

	let stateTmp = {
		page: 1,
		sort: "date",
		query: "",
		type: [],
		day: undefined,
		year: undefined
	};

	const [numberOfRows] = useState(50);
	const [urlState, setUrlState] = useUrlState(stateTmp,
		{
			parseOptions: {
				arrayFormat: 'bracket',
			},
			stringifyOptions: {
				arrayFormat: 'bracket',
			},
		}
	);
	let stateToUse = props.urlParams ? urlState : stateTmp;
	const [state, setState] = useState(stateToUse);

	const [queryTmp, setQueryTmp] = useState(state.query);
	const [pages, setPages] = useState(state.page);
	const [types, setTypes] = useState([]);
	const typeOptions = _.map(types, (type, index) => ({
		key: type['name'],
		text: type['name'] + ' (' + type['count'] + ')',
		value: type['name'],
	}));

	const dayOptions = [];
	const months = [ "January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December" ];
	for(let month = 1; month <= 12; month++) {
		for(let day = 1; day <= 31; day++) {
			if(
				((month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) && day <= 31) ||
				(month === 2 && day <= 29) ||
				((month === 4 || month === 6 || month === 9 || month === 11) && day <= 30)
			) {
				dayOptions.push({key: String(month).padStart(2,'0') + '-' + String(day).padStart(2,'0'),
					text: months[month-1] + ' ' + day,
					value: String(month).padStart(2,'0') + '-' + String(day).padStart(2,'0')
				});
			}
		}
	}
	const yearOptions = [];
	for(let year = 1915; year <= 2024; year++) {
		yearOptions.push({key: year.toString(),
			text: year.toString(),
			value: year.toString()
		});
	}

	const stateHelper = useCallback((stateTmp) => {
		let stateToSet = {};
		if(stateTmp.page !== undefined && Number(state.page) !== Number(stateTmp.page)) {
			stateToSet['page'] = Number(stateTmp.page);
		}
		for(const key in stateTmp) {
			if(key === 'page') { continue; }
			if(key === 'type') {
				if( !(stateTmp[key].length === state[key].length &&
				 stateTmp[key].every( function(this_i,i) { return this_i === state[key][i] } ) ) ) {
					stateToSet[key] = stateTmp[key];
				}
			} else {
				if( stateTmp[key] !== undefined && state[key] !== stateTmp[key] ) {
					stateToSet[key] = stateTmp[key];
				}
			}
		}
		if(Object.keys(stateToSet).length > 0) {
			setState(prevState => ({...prevState, ...stateToSet}));
			if(props.urlParams) {
				setUrlState(prevState => ({...prevState, ...stateToSet}));
			}
		}
	}, [state, setState, props.urlParams, setUrlState]);

	const search = useCallback(() => {
		let pagetmp = state.page;
		if( resetPage || Number(pagetmp) === 0 ) {
			pagetmp = 1;
		}

		let idToUse = id;
		if(event_id) {
			idToUse = "event_" + event_id;
		}
		if(radio_id) {
			idToUse = "radio_" + radio_id;
		}
		if(television_id) {
			idToUse = "television_" + television_id;
		}
		if(film_id) {
			idToUse = "film_" + film_id;
		}
		if(photo_id) {
			idToUse = "photo_" + photo_id;
		}
		if(album_id) {
			idToUse = "album_" + photo_id;
		}
	
		var urlRoot = `${API_ADDRESS}/event/browse`;
		var url = `?page=${pagetmp}`;
		var urlAttachment = '&sort=' + state.sort + '&limit=' + numberOfRows;
		if(state.query) {
			urlAttachment += '&query=' + encodeURIComponent(state.query);
		}
		if(state.type) {
			state.type.forEach(function(filter) {
				urlAttachment += '&type=' + encodeURIComponent(filter);
			});
		}
		if(state.day) {
			urlAttachment += '&day=' + encodeURIComponent(state.day);
		}
		if(state.year) {
			urlAttachment += '&year=' + encodeURIComponent(state.year);
		}

		if(props.take) {
			urlAttachment += '&take=' + encodeURIComponent(props.take);
		}
		if(props.person) {
			urlAttachment += '&person=' + encodeURIComponent(props.person);
		}
		if(props.organisation) {
			urlAttachment += '&organisation=' + encodeURIComponent(props.organisation);
		}

		if(props.urlParams) {
			if(idToUse) {
				navigate('/calendar' + url + urlAttachment);
			}
		}

		if(idToUse && !props.slim) {
			urlAttachment += '&id=' + idToUse;
			setRowsToOpen([...rowsToOpen, idToUse]);			
		}

		fetch(urlRoot + url + urlAttachment)
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				setTotal(json.count);
				setResetPage(false);
				stateHelper({page: json.page});
				setPages(json.pages);
				setLoading(false);
				setChangePage(false);

				var rows = json.events;
				setRows(rows);

				var first_tmp = (json.page-1)*numberOfRows+1;
				var last_tmp = (json.page)*numberOfRows;
				if( first_tmp < 0 ) {
					first_tmp = 0;
				}
				if( first_tmp > json.count ) {
					first_tmp = 0;
					last_tmp = 0;
				}
				if( last_tmp > json.count ) {
					last_tmp = json.count;
				}
				setFirst(first_tmp);
				setLast(last_tmp);

				setTypes(json.types);

				setSearching(false);
				if(!firstLoad) {
					ref.current.scrollIntoView({behavior: 'smooth'});
				}

				if(firstLoad && rows.length === 0 && props.relatedRendered) {
					props.relatedRendered(false);
				}
				setFirstLoad(false);
			})
			.catch(function(ex) {
				setSearching(false);
				console.log(ex);
				// navigate('/500');
			})
	}, [numberOfRows, state, stateHelper, resetPage, firstLoad, id, props, rowsToOpen, navigate, event_id, film_id, photo_id, radio_id, television_id, album_id]);	

	const handlePaginationClick = (e, { activePage }) => stateHelper({page: activePage});

	useEffect(() => { 
		if(props.urlParams) {
			let stateToSet = {};
			for(const key in urlState) {
				if(key === 'page') {
					if(urlState.page !== undefined && Number(state.page) !== Number(urlState.page)) {
						stateToSet['page'] = Number(urlState.page);
					}
				} else {
					if(urlState[key] !== undefined && state[key] !== urlState[key]) {
						stateToSet[key] = urlState[key];
					}
				}
			}
			if(Object.keys(stateToSet).length > 0) {
				setState(prevState => ({...prevState, ...stateToSet}));
				if(stateToSet.query) {
					setQueryTmp(stateToSet.query);
					setLoading(true);
				}
			}
		}
	}, [urlState, state, props.urlParams]);

	useEffect(() => { if( loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])
	useEffect(() => { if( changePage ) { setLoading(true); } }, [changePage])
	useEffect(() => { setChangePage(true) }, [state.page, state.sort])

	return (
		<div ref={ref}>
			<Segment inverted className="browse-table-segment">
				<Dimmer active={loading}>
					<Loader size='large'>Loading</Loader>
				</Dimmer>
				{!props.slim && 
					<Grid columns={6} stackable relaxed='very'>
						<Grid.Column width={1}>
						</Grid.Column>
						<Grid.Column width={6}>
							<Input inverted fluid
								action={{ icon: 'search', onClick: (e) => {setResetPage(true); setChangePage(true);} }}  
								placeholder='Search...'
								value={queryTmp}
								onChange={(e, { value }) => setQueryTmp(value)}
								onBlur={(e) => stateHelper({query:queryTmp})}
								onKeyUp={(e) => {if (e.key === "Enter") {stateHelper({query:queryTmp}); setResetPage(true); setChangePage(true);}}}
							/>
						</Grid.Column>
						<Grid.Column>
							<Dropdown
								placeholder='Type'
								fluid
								multiple
								search
								selection
								options={typeOptions}
								value={state.type}
								onChange={(e, { value }) => {stateHelper({"type": value}); setResetPage(true); setChangePage(true);}}
							/>
						</Grid.Column>
						<Grid.Column>
							<Dropdown
								placeholder='Day'
								fluid
								search
								selection
								clearable
								options={dayOptions}
								value={state.day}
								onChange={(e, { value }) => {stateHelper({"day": value}); setResetPage(true); setChangePage(true);}}
							/>
						</Grid.Column>
						<Grid.Column>
							<Dropdown
								placeholder='Year'
								fluid
								search
								selection
								clearable
								options={yearOptions}
								value={state.year}
								onChange={(e, { value }) => {stateHelper({"year": value}); setResetPage(true); setChangePage(true);}}
							/>
						</Grid.Column>
					</Grid>
				}
				<Table celled inverted selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>Date</Table.HeaderCell>
							<Table.HeaderCell>Location</Table.HeaderCell>
							<Table.HeaderCell>View</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{rows.length === 0 && 
								<Table.Row>
									<Table.Cell colSpan={8}>
										<Segment placeholder inverted>
											<Header icon inverted>
												<Icon name='search' />
												No events match your query
											</Header>
										</Segment>
									</Table.Cell>
								</Table.Row>
						}
						{rows.map(function(event){
							var json_obj = {};
							var event_id = 0;
							let organisations = [];
							let studios = [];
							let rowToOpen = false;
							let rowToOpenId = event.id;
							if(rowsToOpen.includes(event.id)) {
								rowToOpen = true;
							} else {
								if(event.extra_ids) {
									event.extra_ids.forEach(function(eid){
										if(rowsToOpen.includes(eid)) {
											rowToOpen = true;
											rowToOpenId = eid;
										}
									});
								}
							}
							if(rowToOpen) {
								try {
									json_obj = JSON.parse(event.json[0]);
									event_id = json_obj._id;
									if(json_obj._type !== 'event' && json_obj._event) {
										event_id = json_obj._event;
									}
									for(var organisationId in json_obj['organisations']) {
										let organisation = json_obj['organisations'][organisationId];
										organisations.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										organisations.push(<br key={'br_' + organisation._id}/>);
									}
									for(organisationId in json_obj['studios']) {
										let organisation = json_obj['studios'][organisationId];
										studios.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										studios.push(<br key={'br_' + organisation._id}/>);
									}
									for(organisationId in json_obj['sponsor']) {
										let organisation = json_obj['sponsor'][organisationId];
										studios.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										studios.push(<br key={'br_' + organisation._id}/>);
									}
									for(organisationId in json_obj['network']) {
										let organisation = json_obj['network'][organisationId];
										organisations.push(<Link to={'/organisation/' + organisation._id} key={organisation._id}>{organisation.name}</Link>);
										organisations.push(<br key={'br_' + organisation._id}/>);
									}
									console.log(json_obj);
								} catch(ex) {
									console.log(ex);
								}
							}
							var row_link = '/calendar/' + event.id;
							return(
								<Fragment key={event.id}>
									<Table.Row key={event.id} onClick={() => 
									{
										if(rowToOpen) {
											setRowsToOpen(rowsToOpen.filter(id => id !== rowToOpenId));
										} else {
											setRowsToOpen([...rowsToOpen, rowToOpenId]);
										}
									}} className='browse-table-row'>
										<Table.Cell>{event.name}</Table.Cell>
										<Table.Cell>{event.type}</Table.Cell>
										<Table.Cell>{event.date_display}</Table.Cell>
										<Table.Cell>{event.location}</Table.Cell>
										<Table.Cell>
											{rowToOpen && <Icon name='angle up'/>}
											{!rowToOpen && <Icon name='angle down'/>}
										</Table.Cell>
									</Table.Row>
									{rowToOpen &&
										<Table.Row key={event.id + '_open'}>
											<Table.Cell className="merchandise_open" colSpan={6}>
												<Grid>
													<Grid.Row style={{margin:'10px'}}>
														<Grid.Column width={7} floated='left'>
															<Divider horizontal inverted>
																<Header as='h4' inverted>
																	<Icon name='info circle' />
																	Details
																</Header>
															</Divider>
															<Table definition inverted>
																<Table.Body>
																	{'date_display' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Date</Table.HeaderCell>
																			<Table.Cell>{json_obj.date_display}</Table.Cell> 
																		</Table.Row>
																	}
																	{'unconfirmed' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Unconfirmed</Table.HeaderCell>
																			<Table.Cell>There is not sufficient evidence to determine if this event happened or not, or if it did happen, that Sinatra appeared.</Table.Cell> 
																		</Table.Row>
																	}
																	{'cancelled' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Cancelled</Table.HeaderCell>
																			<Table.Cell>This event was either cancelled, or Sinatra cancelled his appearance.</Table.Cell> 
																		</Table.Row>
																	}
																	{'incorrect-listing' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Cancelled</Table.HeaderCell>
																			<Table.Cell>This radio listing is incorrect and did not occur. It is documented here due to appearing in multiple newspapers at the time.</Table.Cell> 
																		</Table.Row>
																	}
																	{'location' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Location</Table.HeaderCell>
																			<Table.Cell>{json_obj.location}</Table.Cell> 
																		</Table.Row>
																	}
																	{'concert_tour' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Tour</Table.HeaderCell>
																			<Table.Cell><Link to={'/concert_tour/' + json_obj.concert_tour._id}>{json_obj.concert_tour.name} ({json_obj.concert_tour.date})</Link></Table.Cell> 
																		</Table.Row>
																	}
																	{('network' in json_obj && organisations.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Network</Table.HeaderCell>
																			<Table.Cell>{organisations}</Table.Cell>
																		</Table.Row>
																	}
																	{('sponsor' in json_obj && studios.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Sponsor</Table.HeaderCell>
																			<Table.Cell>{studios}</Table.Cell>
																		</Table.Row>
																	}
																	{'type' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Type</Table.HeaderCell>
																			<Table.Cell>{json_obj.type}</Table.Cell> 
																		</Table.Row>
																	}
																	{'film' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Film</Table.HeaderCell>
																			<Table.Cell><Link to={'/film/' + json_obj.film._id}>{json_obj.film.title}</Link></Table.Cell> 
																		</Table.Row>
																	}
																	{('organisations' in json_obj && organisations.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Organisations</Table.HeaderCell>
																			<Table.Cell>{organisations}</Table.Cell>
																		</Table.Row>
																	}
																	{('studios' in json_obj && studios.length > 0) &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Studios</Table.HeaderCell>
																			<Table.Cell>{studios}</Table.Cell>
																		</Table.Row>
																	}
																	{'titles' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Alternate Titles</Table.HeaderCell>
																			<Table.Cell>
																				{json_obj.titles.map(function(title) {
																					return <p key={title.title}>{title.type}: <i>{title.title}</i></p>
																				})}
																			</Table.Cell>
																		</Table.Row>
																	}
																	{'session_number' in json_obj &&
																		<Table.Row>
																			<Table.HeaderCell width={2}>Session #</Table.HeaderCell>
																			<Table.Cell>{json_obj.session_number}</Table.Cell> 
																		</Table.Row>
																	}
																</Table.Body>
															</Table>
															{'discographical-notes' in json_obj && 
																<>
																	<h6>Discographical Notes</h6>
																	<p dangerouslySetInnerHTML={{__html: json_obj['discographical-notes']}}></p>
																</>
															}
															{'notes' in json_obj && 
																<>
																	<h6>Notes</h6>
																	<p dangerouslySetInnerHTML={{__html: json_obj.notes}}></p>
																</>
															}
														</Grid.Column>
														<Grid.Column width={7} floated='right'>
															{('people' in json_obj && Object.keys(json_obj.people).length > 0) &&
																<>
																	<Divider horizontal inverted>
																		<Header as='h4' inverted>
																			<Icon name='male' />
																			People
																		</Header>
																	</Divider>
																	<SessionPersonnel people={json_obj.people} sessions={[]} session={false}/>
																</>
															}		
															{('companies' in json_obj && Object.keys(json_obj.companies).length > 0) &&
																<>
																	<Divider horizontal inverted>
																		<Header as='h4' inverted>
																			<Icon name='male' />
																			Companies
																		</Header>
																	</Divider>
																	<Companies companies={json_obj.companies}/>
																</>
															}		
															{('recording_people' in json_obj && Object.keys(json_obj.recording_people).length > 0) &&
																<>
																	<Divider horizontal inverted>
																		<Header as='h4' inverted>
																			<Icon name='male' />
																			Musicians
																		</Header>
																	</Divider>
																	<SessionPersonnel people={json_obj.recording_people} sessions={[]} session={true} recordingNotes={json_obj.recording_people_notes}/>
																</>
															}		
														</Grid.Column>
													</Grid.Row>
													<Grid.Row>
														{('photos' in json_obj && json_obj.photos.length > 0) &&
															<>
																<Divider horizontal inverted>
																	<Header as='h4' inverted>
																		<Icon name='camera' />
																		Photos
																	</Header>
																</Divider>
																<ImageCollage images={json_obj.photos} type="photos" key={json_obj._id}/>
															</>
														}
														{('attachments' in json_obj && json_obj.attachments.length > 0) &&
															<>
																<Divider horizontal inverted>
																	<Header as='h4' inverted>
																		<Icon name='file' />
																		Attachments
																	</Header>
																</Divider>
																<Attachments images={json_obj.attachments} type="promos" key={json_obj._id} id={json_obj._id}/>
															</>
														}
														{('script' in json_obj && json_obj.script.length > 0) &&
															<>
																<Divider horizontal inverted>
																	<Header as='h4' inverted>
																		<Icon name='file' />
																		Script
																	</Header>
																</Divider>
																<ImageCollage images={json_obj.script} type="scripts" key={json_obj._id}/>
															</>
														}
													</Grid.Row>
													{('tracks' in json_obj && json_obj.tracks.length > 0) &&
														<Grid.Row style={{marginLeft:'20px', marginRight: '20px'}}>
															<Divider horizontal inverted>
																<Header as='h4' inverted>
																	<Icon name='list ol' />
																	Tracks
																</Header>
															</Divider>
															<Table definition inverted>
																<Table.Body>
																	<Table.Row>
																		<Table.HeaderCell width={2}>Position</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Name</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Artist</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Length</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Recording</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Take</Table.HeaderCell>
																		<Table.HeaderCell width={2}>Variant / Excerpt</Table.HeaderCell>
																	</Table.Row>
																	{json_obj.tracks.map(function(track) {
																		let name = track.name;
																		let recording = '';
																		let take = '';
																		let variant = '';
																		if('recording' in track) {
																			for(var pos in track.recording.sessions) {
																				if(recording !== '') { recording += '\n'; }
																				let session = track.recording.sessions[pos];
																				recording += session.obj.date_display;
																			}
																			recording = <Link to={'/recording/' + track.recording._id}>{recording}</Link>;
																		} else if('radio' in track) {
																			recording = <Link to={'/radio/' + track.radio._id}>{track.radio.date_display}</Link>;
																			if('name' in track.radio) {
																				take = track.radio.name;
																			} else {
																				take = 'Broadcast';
																			}
																		} else if('film' in track) {
																			recording = <Link to={'/film/' + track.film._id}>{track.film.title}</Link>;
																			take = 'Film';
																		} else if('television' in track) {
																			recording = <Link to={'/television/' + track.television._id}>{track.television.date_display}</Link>;
																			take = 'Television';
																		} else if('event' in track) {
																			recording = <Link to={'/event/' + track.event._id}>{track.event.date_display}</Link>;
																			take = track.event.type + ' excerpt';
																		} else if('not_sinatra' in track) {
																			recording = <i>Not Sinatra</i>;
																		} else if('not_singing' in track) {
																			recording = <i>Not Singing</i>;
																		}
																		if('variant_takes' in track) {
																			let recordings = [];
																			let takes = [];
																			track.variant_takes.map(function(variant_take) {
																				if(recordings.length > 0) {
																					recordings.push(<br/>);
																				}
																				let recStr = '';
																				for(var pos in variant_take.recording.sessions) {
																					if(recStr !== '') { recStr += '\n'; }
																					let session = variant_take.recording.sessions[pos];
																					recStr += session.obj.date_display;
																				}
																				recordings.push(<Link to={'/recording/' + variant_take.recording._id}>{recStr}</Link>);

																				if(takes.length > 0) {
																					takes.push(<br/>);
																				}
																				takes.push(<Link to={'/recording/' + variant_take.recording._id}>{variant_take.name}</Link>);
																			});
																			recording = <>{recordings}</>;
																			take = <>{takes}</>;
																		} else if('take' in track) {
																			take = <Link to={'/recording/' + track.recording._id}>{track.take.name}</Link>;
																		}
																		if('variant' in track) {
																			variant = <span>{track.variant.name}</span>;
																		} else if('radio_variant' in track) {
																			variant = <span>{track.radio_variant.name}</span>;
																		} else if('film_variant' in track) {
																			variant = <span>{track.film_variant.name}</span>;
																		} else if('television_variant' in track) {
																			variant = <span>{track.television_variant.name}</span>;
																		} else if('event_variant' in track) {
																			variant = <span>{track.event_variant.name}</span>;
																		}

																		if('radio' in track && 'tracks' in track.radio) {
																			let recordingDates = '';
																			let songNames = '';
																			let takeNames = '';
																			let variantNames = '';
																			let trackPos = 0;
																			track.radio.tracks.map(function(rTrack) {
																				if('take' in rTrack && 'recording' in rTrack) {
																					trackPos++;
																					if(recordingDates !== '') { recordingDates += '\n'; }
																					recordingDates += '↳ ' + trackPos + ') ';
																					for(var pos in rTrack.recording.sessions) {
																						if(pos > 0) { recordingDates += ', '; }
																						let session = rTrack.recording.sessions[pos];
																						recordingDates += session.obj.date_display;
																					}
																					if(songNames !== '') { songNames += '\n'; }
																					songNames += '↳ ' + trackPos + ') ' + rTrack.name;
																					if(takeNames !== '') { takeNames += '\n'; }
																					takeNames += '↳ ' + trackPos + ') ' + rTrack.take.name;
																					if('variant' in rTrack) {
																						if(variantNames !== '') { variantNames += '\n'; }
																						variantNames += '↳ ' + trackPos + ') ' + rTrack.variant.name;
																					} else {
																						variantNames += '\n';
																					}
																					if('excerpt' in rTrack || 'excerpt-unknown' in rTrack) {
																						let excerpt = '';
																						if('excerpt' in rTrack) {
																							excerpt = 'Excerpt details: ' + rTrack.excerpt;
																						} else {
																							excerpt = 'Excerpt details: unknown';
																						}
																						if(variantNames) {
																							variantNames += '\n' + excerpt;
																						} else {
																							variantNames = excerpt;
																						}
																					}
																				}
																				return rTrack.pos;
																			});
																			recording = <>{recording}<br/>{recordingDates}</>;
																			name = <>{name}<br/>{songNames}</>;
																			take = <>{take}<br/>{takeNames}</>;
																			variant = <>{variant}<br/>{variantNames}</>;
																		}

																		if('excerpt' in track || 'excerpt-unknown' in track) {
																			let excerpt = '';
																			if('excerpt' in track) {
																				excerpt = 'Excerpt details: ' + track.excerpt;
																			} else {
																				excerpt = 'Excerpt details: unknown';
																			}
																			if(variant) {
																				variant = <>{variant}<br/><br/><span>{excerpt}</span></>;
																			} else {
																				variant = <span>{excerpt}</span>;
																			}
																		}

																		return <Table.Row key={track.position}>
																			<Table.Cell>{track.position}</Table.Cell>
																			<Table.Cell>{name}{'actual_name' in track && <><br/><i>[corrected: {track.actual_name}]</i></>}</Table.Cell> 
																			<Table.Cell>{track.artist}{'actual_artist' in track && <><br/><i>[corrected: {track.actual_artist}]</i></>}</Table.Cell> 
																			<Table.Cell>{track.length}</Table.Cell> 
																			<Table.Cell>{recording}</Table.Cell>
																			<Table.Cell>{take}</Table.Cell> 
																			<Table.Cell>{variant}</Table.Cell> 
																		</Table.Row>
																	})}
																</Table.Body>
															</Table>
														</Grid.Row>
													}
													{('recordings' in json_obj && json_obj.recordings === true) &&
														<Grid.Row>
															<Divider horizontal inverted>
																<Header as='h4' inverted>
																	<Icon name='music' />
																	Songs
																</Header>
															</Divider>
															<RecordingBrowseCommon slim={true} event={event_id}/>
														</Grid.Row>
													}
													{('variants' in json_obj && json_obj.variants.length > 0) &&
														<Grid.Row>
															<Divider horizontal inverted>
																<Header as='h4' inverted>
																	{json_obj._type === 'film' && 
																		<Icon name='file video' />
																	}
																	{json_obj._type !== 'film' && 
																		<Icon name='file audio' />
																	}
																	Variants 
																</Header>
															</Divider>
															<Table inverted>
																<Table.Body>
																	<Table.Row>
																		<Table.HeaderCell>Name</Table.HeaderCell>
																		<Table.HeaderCell>Types</Table.HeaderCell>
																		<Table.HeaderCell>Length</Table.HeaderCell>
																		<Table.HeaderCell>Notes</Table.HeaderCell>
																		<Table.HeaderCell></Table.HeaderCell>
																	</Table.Row>
																	{json_obj.variants.map(function(variant){
																		return <Table.Row key={variant._id}>
																			<Table.Cell>{variant.name}</Table.Cell>
																			<Table.Cell>{variant.types.map(function(type){
																				return <span key={type}>{type}<br/></span>
																			})}</Table.Cell>
																			<Table.Cell>{variant.length}</Table.Cell>
																			<Table.Cell>
																				<p dangerouslySetInnerHTML={{__html: variant.notes}}></p>
																				{variant.event_variants && 
																					<><b>Incorporates the following:</b>
																						<ul>
																							{variant.event_variants.map(function(ev) {
																								return <li key={ev._id}><Link to={'/event_variant/' + ev._id}>{ev.name}</Link></li>
																							})}
																						</ul>
																					</>
																				}
																				{variant.radio_variants && 
																					<><b>Incorporates the following:</b>
																						<ul>
																							{variant.radio_variants.map(function(ev) {
																								return <li key={ev._id}><Link to={'/radio_variant/' + ev._id}>{ev.name}</Link></li>
																							})}
																						</ul>
																					</>
																				}
																				{variant.takes && 
																					<><b>Incorporates the following:</b>
																						<ul>
																							{variant.takes.map(function(ev) {
																								return <li key={ev._id}><Link to={'/recording/' + ev.recording._id + '#take_' + ev._id}>{ev.recording.song.title + ' - ' + ev.recording.session.date_display + ' (' + ev.name + ')'}</Link></li>
																							})}
																						</ul>
																					</>
																				}
																				{variant.skip_tracks && 
																					<p><b>Does not include the following tracks: </b>
																						{variant.skip_tracks}
																					</p>
																				}
																			</Table.Cell>
																			<Table.Cell>
																				{'recording-audio' in variant && <Icon name='sound' title='Audio recording in-circulation'/>}
																				{'recording-video' in variant && <Icon name='video' title='Video recording in-circulation'/>}
																				{'sinatraology_collection' in variant && <Icon name='check square outline' title='In the Sinatraology Collection'/>}
																			</Table.Cell>
																		</Table.Row>
																	})}
																</Table.Body>
															</Table>
														</Grid.Row>
													}
													{json_obj._type === 'event' && 
														<Grid.Row>
															<RecordingTakeReleases key={json_obj._id} event={json_obj._id} event_variants={json_obj.event_variants}/>
														</Grid.Row>
													}
													{json_obj._type === 'film' && 
														<Grid.Row>
															<RecordingTakeReleases key={json_obj._id} film={json_obj._id} film_variants={json_obj.variants}/>
														</Grid.Row>
													}
													{json_obj._type === 'radio' && 
														<Grid.Row>
															<RecordingTakeReleases key={json_obj._id} radio={json_obj._id} radio_variants={json_obj.variants}/>
														</Grid.Row>
													}
													{json_obj._type === 'television' && 
														<Grid.Row>
															<RecordingTakeReleases key={json_obj._id} television={json_obj._id} television_variants={json_obj.variants}/>
														</Grid.Row>
													}
													{json_obj._type === 'album' &&
														<ReleaseBrowseCommon key={'album_' + json_obj._id} album={json_obj._id} urlParams={false}/>
													}
													<Grid.Row>
													  <EventPublications event={event.id} concert_tour={json_obj.concert_tour}/>
													</Grid.Row>
													<Grid.Row>
													  <EventMerchandise event={event.id} concert_tour={json_obj.concert_tour}/>
													</Grid.Row>
													<Grid.Column width={3} floated='right' style={{ textAlign: 'right' }}>
														{(('references' in json_obj && json_obj.references.length > 0) || 'radio-listings' in json_obj) &&
															<ReferencesPopup references={json_obj.references} radio_listings={json_obj['radio-listings']}/>
														}
														<a href={row_link}><Icon name='linkify'/></a>
													</Grid.Column>
												</Grid>
											</Table.Cell>
										</Table.Row>
									}
								</Fragment>
							);
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell>
								Showing {first}-{last} of {total} events.
							</Table.HeaderCell>
							<Table.HeaderCell colSpan='7' textAlign='right'>
								<Pagination activePage={state.page} totalPages={pages} firstItem={null} lastItem={null} onPageChange={handlePaginationClick} siblingRange={1} inverted/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</div>
	);
}

// 														<a href={edit_link} target="_blank"><Icon name='edit'/></a>

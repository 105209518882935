import { Header, Table, Divider, Icon } from 'semantic-ui-react'


export function References (props) {
	var references = [];
	var acknowledgements = undefined;
	if( props.references ) {
		references = props.references;
	}
	if( props.radio_listings ) {
		references.push({'title': '[radio listings]', 'publisher': '[various newspapers]'})
	}
	if( props.acknowledgements ) {
		acknowledgements = props.acknowledgements;
	}

	if( references.length > 0 ) {
		references = references.filter((thing, index, self) =>
			index === self.findIndex((t) => (
				t.title === thing.title && t.year === thing.year && t.publisher === thing.publisher && t.author === thing.author && t.type === thing.type && t.url === thing.url
			))
		)

		references.sort((a,b) => { 
			var yr1 = Date.parse(a.year);
			var yr2 = Date.parse(b.year);

			if( yr1 > yr2 ) {
				return 1;
			}
			if( yr1 < yr2 ) {
				return -1;
			}
			var title1 = a.title;
			if( title1.startsWith('The ') ) {
				title1 = title1.substr(4);
			}
			var title2 = b.title;
			if( title2.startsWith('The ') ) {
				title2 = title2.substr(4);
			}
			if( title1 > title2 ) {
				return 1;
			}
			if( title1 < title2 ) {
				return -1;
			}
			if( a.publisher > b.publisher ) {
				return 1;
			}
			if( a.publisher < b.publisher ) {
				return -1;
			}
			return 0;
		}); 
	}
	
	if( references.length === 0 && acknowledgements === undefined  ) {
		return '';
	}

	return (
		<>
			{!props.skip_divider &&
				<Divider horizontal inverted>
					<Header as='h4' inverted>
						<Icon name='book' />
						References
					</Header>
				</Divider>
			}
			{references.length > 0 &&
				<Table definition celled inverted>
					<Table.Body>
						<Table.Row>
							<Table.HeaderCell>Title</Table.HeaderCell>
							<Table.HeaderCell>Author</Table.HeaderCell>
							<Table.HeaderCell>Publisher</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>Year</Table.HeaderCell>
							<Table.HeaderCell>URL</Table.HeaderCell>
						</Table.Row>
						{references.map(function(reference){
							return <Table.Row key={reference.title}>
									<Table.Cell>{reference.title}</Table.Cell>
									<Table.Cell>{reference.author}</Table.Cell>
									<Table.Cell>{reference.publisher}</Table.Cell>
									<Table.Cell>{reference.type}</Table.Cell>
									<Table.Cell>{reference.year}</Table.Cell>
									<Table.Cell>{'url' in reference && <a href={reference.url} target="_blank" rel="noopener noreferrer nofollow">Link</a>}</Table.Cell>
								</Table.Row>
						})}
					</Table.Body>
					{acknowledgements !== undefined && 
						<Table.Footer>
							<Table.Row>
								<Table.HeaderCell colSpan={6}>
									<b>Acknowledgements</b>: {acknowledgements}
								</Table.HeaderCell>
							</Table.Row>
						</Table.Footer>
					}
				</Table>
			}
		</>
	);
}
import React from 'react';
import { Menu, Grid } from 'semantic-ui-react'
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

export function Header(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const activeItem = location.pathname.split('/')[1];

	const onClickHandler = (e, link) => {
		if(e.ctrlKey || e.metaKey){
			window.open(link, '_blank');
		} else {
			navigate(link);
		}
	}

	return (
		<>
			<div style={{marginBottom:'10px'}}>
				<Menu inverted pointing secondary>
					<Menu.Item header
						active={activeItem === ''}
						onClick={(e) => onClickHandler(e, '/')}
					>Sinatraology</Menu.Item>
					<Menu.Item
						name='recordings'
						active={activeItem === 'recording'}
						onClick={(e) => onClickHandler(e, '/recording')}
					/>
					<Menu.Item
						name='releases'
						active={activeItem === 'release'}
						onClick={(e) => onClickHandler(e, '/release')}
					/>
					<Menu.Item
						name='calendar'
						active={activeItem === 'calendar'}
						onClick={(e) => onClickHandler(e, '/calendar')}
					/>
					<Menu.Item
						name='sheet music'
						active={activeItem === 'sheet_music'}
						onClick={(e) => onClickHandler(e, '/sheet_music')}
					/>
					<Menu.Item
						name='merchandise'
						active={activeItem === 'merchandise'}
						onClick={(e) => onClickHandler(e, '/merchandise')}
					/>
					<Menu.Item
						name='publications'
						active={activeItem === 'publication'}
						onClick={(e) => onClickHandler(e, '/publication')}
					/>
					<Menu.Item
						name='adverts'
						active={activeItem === 'advert'}
						onClick={(e) => onClickHandler(e, '/advert')}
					/>
					<Menu.Item
						name='soundtrack'
						active={activeItem === 'soundtrack'}
						onClick={(e) => onClickHandler(e, '/soundtrack')}
					/>
					<Menu.Item
						name='songs'
						active={activeItem === 'songs'}
						onClick={(e) => onClickHandler(e, '/song')}
					/>
					<Menu.Item
						name='people'
						active={activeItem === 'person'}
						onClick={(e) => onClickHandler(e, '/person')}
					/>
					<Menu.Item
						name='organisations'
						active={activeItem === 'organisations'}
						onClick={(e) => onClickHandler(e, '/organisation')}
					/>
				</Menu>
			</div>
		</>
	);
} 

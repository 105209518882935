import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API_ADDRESS } from './../../constants.js';
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import useUrlState from '@ahooksjs/use-url-state';
import _ from 'lodash'
import { Icon, Table, Dimmer, Loader, Segment, Button, Grid, Divider, Pagination, Input, Header, Dropdown } from 'semantic-ui-react'

import { ImageGallery } from './../subcomponents/ImageGallery';
import { ReferencesPopup } from './../subcomponents/ReferencesPopup';

export function PublicationBrowseCommon(props) {
	const navigate = useNavigate();
	const {id} = useParams();
	const ref = React.useRef(null);

	const [firstLoad, setFirstLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [resetPage, setResetPage] = useState(false);
	const [changePage, setChangePage] = useState(false);
	const [searching, setSearching] = useState(false);
	const [rows, setRows] = useState([]);
	const [rowsToOpen, setRowsToOpen] = useState([]);
	const [first, setFirst] = useState(0);
	const [last, setLast] = useState(0);

	let stateTmp = {
		page: 1,
		sort: "date",
		query: "",
	};

	const [numberOfRows] = useState(50);
	const [urlState, setUrlState] = useUrlState(stateTmp,
		{
			parseOptions: {
				arrayFormat: 'bracket',
			},
			stringifyOptions: {
				arrayFormat: 'bracket',
			},
		}
	);
	let stateToUse = props.urlParams ? urlState : stateTmp;
	const [state, setState] = useState(stateToUse);

	const [queryTmp, setQueryTmp] = useState(state.query);
	const [pages, setPages] = useState(state.page);
	const [sortByDate, setSortByDate] = useState(state.sort === 'date');
	const [sortByTitle, setSortByTitle] = useState(state.sort === 'title');

	const [types, setTypes] = useState([]);
	const typeOptions = _.map(types, (type, index) => ({
		key: type['name'],
		text: type['name'] + ' (' + type['count'] + ')',
		value: type['name'],
	}));

	const stateHelper = useCallback((stateTmp) => {
		let stateToSet = {};
		if(stateTmp.page !== undefined && Number(state.page) !== Number(stateTmp.page)) {
			stateToSet['page'] = Number(stateTmp.page);
		}
		for(const key in stateTmp) {
			if(key === 'page') { continue; }
			if(stateTmp[key] !== undefined && state[key] !== stateTmp[key]) {
				stateToSet[key] = stateTmp[key];
			}
		}
		if(Object.keys(stateToSet).length > 0) {
			setState(prevState => ({...prevState, ...stateToSet}));
			if(props.urlParams) {
				setUrlState(prevState => ({...prevState, ...stateToSet}));
			}
		}
	}, [state, setState, props.urlParams, setUrlState]);
	
	const search = useCallback(() => {
		let pagetmp = state.page;
		if( resetPage || Number(pagetmp) === 0 ) {
			pagetmp = 1;
		}
	
		var urlRoot = `${API_ADDRESS}/publication/browse`;
		var url = `?page=${pagetmp}`;
		var urlAttachment = '&sort=' + state.sort + '&limit=' + numberOfRows;
		if(state.query) {
			urlAttachment += '&query=' + encodeURIComponent(state.query);
		}
		if(state.type) {
			state.type.forEach(function(filter) {
				urlAttachment += '&type=' + encodeURIComponent(filter);
			});
		}
		if(props.event) {
			urlAttachment += '&event=' + props.event;
		}
		if(props.film) {
			urlAttachment += '&film=' + props.film;
		}
		if(props.television) {
			urlAttachment += '&television=' + props.television;
		}
		if(props.concert_tour) {
			urlAttachment += '&concert_tour=' + props.concert_tour;
		}
		if(props.album) {
			urlAttachment += '&album=' + props.album;
		}
		if(props.person) {
			urlAttachment += '&person=' + encodeURIComponent(props.person);
		}
		if(props.organisation) {
			urlAttachment += '&organisation=' + encodeURIComponent(props.organisation);
		}

		if(props.urlParams) {
			if(id) {
				navigate('/publication' + url + urlAttachment);
			}
		}

		if(id) {
			urlAttachment += '&publication=' + id;
			setRowsToOpen([...rowsToOpen, id]);			
		}

		fetch(urlRoot + url + urlAttachment)
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				setTotal(json.count);
				setResetPage(false);
				stateHelper({page: json.page});
				setPages(json.pages);
				setLoading(false);
				setChangePage(false);

				var rows = json.publications;
				setRows(rows);

				var first_tmp = (json.page-1)*numberOfRows+1;
				var last_tmp = (json.page)*numberOfRows;
				if( first_tmp < 0 ) {
					first_tmp = 0;
				}
				if( first_tmp > json.count ) {
					first_tmp = 0;
					last_tmp = 0;
				}
				if( last_tmp > json.count ) {
					last_tmp = json.count;
				}
				setFirst(first_tmp);
				setLast(last_tmp);

				setTypes(json.types);

				setSearching(false);
				if(!firstLoad) {
					ref.current.scrollIntoView({behavior: 'smooth'});
				}

				if(firstLoad && rows && rows.length === 0 && props.relatedRendered) {
					props.relatedRendered(false);
				}
				setFirstLoad(false);
			})
			.catch(function(ex) {
				setSearching(false);
				console.log(ex);
				// navigate('/500');
			})
	}, [numberOfRows, state, stateHelper, resetPage, navigate, firstLoad, id, props, rowsToOpen]);	

	const handlePaginationClick = (e, { activePage }) => stateHelper({page: activePage});
	const changeSortField = (field) => stateHelper({sort:field});

	useEffect(() => { 
		if(props.urlParams) {
			let stateToSet = {};
			for(const key in urlState) {
				if(key === 'page') {
					if(urlState.page !== undefined && Number(state.page) !== Number(urlState.page)) {
						stateToSet['page'] = Number(urlState.page);
					}
				} else {
					if(urlState[key] !== undefined && state[key] !== urlState[key]) {
						stateToSet[key] = urlState[key];
					}
				}
			}
			if(Object.keys(stateToSet).length > 0) {
				setState(prevState => ({...prevState, ...stateToSet}));
				if(stateToSet.query) {
					setQueryTmp(stateToSet.query);
					setLoading(true);
				}
			}
		}
	}, [urlState, state, props.urlParams]);

	useEffect(() => { if( loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])
	useEffect(() => { if( changePage ) { setLoading(true); } }, [changePage])
	useEffect(() => { setChangePage(true) }, [state.page, state.sort])
	useEffect(() => {
		if(state.sort === 'title') {
			setSortByTitle(true);
			setSortByDate(false);
		} else {
			setSortByTitle(false);
			setSortByDate(true);
		}
		setResetPage(true);
		setChangePage(true);
	}, [state.sort])

	return (
		<div ref={ref}>
			<Segment inverted className="browse-table-segment">
				<Dimmer active={loading}>
					<Loader size='large'>Loading</Loader>
				</Dimmer>
				{!props.slim &&
					<Grid columns={3} stackable relaxed='very' className="width100">
						<Grid.Column>
							<Button.Group>
								<Button positive={sortByTitle} onClick={() => changeSortField('title')}>Sort by Title</Button>
								<Button.Or />
								<Button positive={sortByDate} onClick={() => changeSortField('date')}>Sort by Year</Button>
							</Button.Group>
						</Grid.Column>
						<Grid.Column>
							<Input inverted fluid
								action={{ icon: 'search', onClick: (e) => {setResetPage(true); setChangePage(true);} }}  
								placeholder='Search...'
								value={queryTmp}
								onChange={(e, { value }) => setQueryTmp(value)}
								onBlur={(e) => stateHelper({query:queryTmp})}
								onKeyUp={(e) => {if (e.key === "Enter") {stateHelper({query:queryTmp}); setResetPage(true); setChangePage(true);}}}
							/>
						</Grid.Column>
						<Grid.Column>
							<Dropdown
								placeholder='Type'
								fluid
								multiple
								search
								selection
								options={typeOptions}
								value={state.type}
								onChange={(e, { value }) => {stateHelper({"type": value}); setResetPage(true); setChangePage(true);}}
							/>
						</Grid.Column>
					</Grid>
				}
				<Table celled inverted selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Title</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>Year</Table.HeaderCell>
							<Table.HeaderCell>Publisher</Table.HeaderCell>
							<Table.HeaderCell>View</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{(rows && rows.length === 0) && 
								<Table.Row>
									<Table.Cell colSpan={8}>
										<Segment placeholder inverted>
											<Header icon inverted>
												<Icon name='search' />
												No publications match your query
											</Header>
										</Segment>
									</Table.Cell>
								</Table.Row>
						}
						{rows.map(function(publication){
							var json_obj = {};
							var publishers = [];
							var authors = [];
							var events = [];
							var films = [];
							var televisions = [];
							var concert_tours = [];
							var albums = [];
							if(rowsToOpen.includes(publication.id)) {
								try {
									json_obj = JSON.parse(publication.json[0]);
									console.log(json_obj);
									for(var publisherPos in json_obj['publisher']) {
										let publisher = json_obj['publisher'][publisherPos];
										publishers.push(<Link to={'/organisation/' + publisher._id} key={publisher._id}>{publisher.name}</Link>);
										publishers.push(<br key={'br_' + publisher._id}/>);
									}
									for(var authorPos in json_obj['author']) {
										let author = json_obj['author'][authorPos];
										authors.push(<Link to={'/organisation/' + author._id} key={author._id}>{author.fullName}</Link>);
										authors.push(<br key={'br_' + author._id}/>);
									}
									for(var eventPos in json_obj['event']) {
										let event = json_obj['event'][eventPos];
										events.push(<Link to={'/event/' + event._id} key={event._id}>{event['date_display']}</Link>);
										events.push(<br key={'br_' + event._id}/>);
									}
									for(var filmPos in json_obj['film']) {
										let film = json_obj['film'][filmPos];
										films.push(<Link to={'/film/' + film._id} key={film._id}>{film['title']}</Link>);
										films.push(<br key={'br_' + film._id}/>);
									}
									for(var televisionPos in json_obj['television']) {
										let television = json_obj['television'][televisionPos];
										televisions.push(<Link to={'/television/' + television._id} key={television._id}>{television['date_display']}</Link>);
										televisions.push(<br key={'br_' + television._id}/>);
									}
									for(var concertTourPos in json_obj['concert_tour']) {
										let concert_tour = json_obj['concert_tour'][concertTourPos];
										concert_tours.push(<Link to={'/concert_tour/' + concert_tour._id} key={concert_tour._id}>{concert_tour['name']}</Link>);
										concert_tours.push(<br key={'br_' + concert_tour._id}/>);
									}
									for(var albumPos in json_obj['album']) {
										let album = json_obj['album'][albumPos];
										albums.push(<Link to={'/album/' + album._id} key={album._id}>{album['title']}</Link>);
										albums.push(<br key={'br_' + album._id}/>);
									}
								} catch(ex) {
									console.log(ex);
								}
							}
							var row_link = '/publication/' + publication.id;
							return(
								<Fragment key={publication.id}>
									<Table.Row key={publication.id} onClick={() => 
									{
										if(rowsToOpen.includes(publication.id)) {
											setRowsToOpen(rowsToOpen.filter(id => id !== publication.id));
										} else {
											setRowsToOpen([...rowsToOpen, publication.id]);
										}
									}} className='browse-table-row'>
										<Table.Cell>{publication.title}</Table.Cell>
										<Table.Cell>{publication.type}</Table.Cell>
										<Table.Cell>{publication.date_display}</Table.Cell>
										<Table.Cell>{publication.publisher_name} ({publication.country})</Table.Cell>
										<Table.Cell>
											{rowsToOpen.includes(publication.id) && <Icon name='angle up'/>}
											{!rowsToOpen.includes(publication.id) && <Icon name='angle down'/>}
										</Table.Cell>
									</Table.Row>
									{rowsToOpen.includes(publication.id) &&
										<Table.Row key={publication.id + '_open'}>
											<Table.Cell className="merchandise_open" colSpan={6}>
												<Grid>
													<Grid.Column width={7} floated='left'>
														{('images' in json_obj && json_obj.images.length > 0) &&
															<ImageGallery images={json_obj.images} type="publication"/>
														}
													</Grid.Column>
													<Grid.Column width={7} floated='right'>
														<Divider horizontal inverted>
															<Header as='h4' inverted>
																<Icon name='info circle' />
																Details
															</Header>
														</Divider>
														<Table definition inverted>
															<Table.Body>
																{'edition' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Edition</Table.HeaderCell>
																		<Table.Cell>{json_obj.edition}</Table.Cell> 
																	</Table.Row>
																}
																{(authors && authors.length > 0) &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Author</Table.HeaderCell>
																		<Table.Cell>{authors}</Table.Cell> 
																	</Table.Row>
																}
																{(publishers && publishers.length > 0) &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Publisher</Table.HeaderCell>
																		<Table.Cell>{publishers}</Table.Cell> 
																	</Table.Row>
																}
																{(events && events.length > 0) &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Event</Table.HeaderCell>
																		<Table.Cell>{events}</Table.Cell> 
																	</Table.Row>
																}
																{(concert_tours && concert_tours.length > 0) &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Tour</Table.HeaderCell>
																		<Table.Cell>{concert_tours}</Table.Cell> 
																	</Table.Row>
																}
																{(films && films.length > 0) &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Film</Table.HeaderCell>
																		<Table.Cell>{films}</Table.Cell> 
																	</Table.Row>
																}
																{(televisions && televisions.length > 0) &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Television</Table.HeaderCell>
																		<Table.Cell>{televisions}</Table.Cell> 
																	</Table.Row>
																}
																{(albums && albums.length > 0) &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Album</Table.HeaderCell>
																		<Table.Cell>{albums}</Table.Cell> 
																	</Table.Row>
																}
																{'country' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Country</Table.HeaderCell>
																		<Table.Cell>{json_obj.country}</Table.Cell> 
																	</Table.Row>
																}
																{'date_display' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Release Date</Table.HeaderCell>
																		<Table.Cell>{json_obj.date_display}</Table.Cell> 
																	</Table.Row>
																}
																{'type' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Type</Table.HeaderCell>
																		<Table.Cell>{json_obj.type}</Table.Cell> 
																	</Table.Row>
																}
																{'isbn' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>ISBN</Table.HeaderCell>
																		<Table.Cell>{json_obj.isbn}</Table.Cell> 
																	</Table.Row>
																}
																{'catalog' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Catalog</Table.HeaderCell>
																		<Table.Cell>{json_obj.catalog}</Table.Cell> 
																	</Table.Row>
																}
																{'loc-catalog' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Library of Congress Catalog Card Number</Table.HeaderCell>
																		<Table.Cell>{json_obj['loc-catalog']}</Table.Cell> 
																	</Table.Row>
																}
																{'barcode' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Barcode</Table.HeaderCell>
																		<Table.Cell>{json_obj.barcode}</Table.Cell> 
																	</Table.Row>
																}
																{'numbered' in json_obj &&
																	<Table.Row>
																		<Table.HeaderCell width={2}>Number of Copies</Table.HeaderCell>
																		<Table.Cell>{json_obj.numbered}</Table.Cell> 
																	</Table.Row>
																}
															</Table.Body>
														</Table>
														{'notes' in json_obj && 
															<>
																<h6>Notes</h6>
																<p dangerouslySetInnerHTML={{__html: json_obj.notes}}></p>
															</>
														}
													</Grid.Column>
													<Grid.Column width={3} floated='right' style={{ textAlign: 'right' }}>
														{('references' in json_obj && json_obj.references.length > 0) &&
															<ReferencesPopup references={json_obj.references}/>
														}
														<a href={row_link}><Icon name='linkify'/></a>
													</Grid.Column>
												</Grid>
											</Table.Cell>
										</Table.Row>
									}
								</Fragment>
							);
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell>
								Showing {first}-{last} of {total} publications.
							</Table.HeaderCell>
							<Table.HeaderCell colSpan='7' textAlign='right'>
								<Pagination activePage={state.page} totalPages={pages} firstItem={null} lastItem={null} onPageChange={handlePaginationClick} siblingRange={1} inverted/>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
		</div>
	);
}
import React, { Fragment } from 'react';
import { Table } from 'semantic-ui-react'
import { Link } from "react-router-dom";

export function Companies (props) {
var rows = props.companies;
	var element = "No company data available";
	if( rows && Object.keys(rows).length > 0 ) {
		element = 
			<Table inverted>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Role</Table.HeaderCell>
						<Table.HeaderCell>Company</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{Object.keys(rows).map(function(role, i) {
						let rowSpan = rows[role].length;
						let maxOr = 0;
						for(let i = 0; i < rows[role].length; i++) {
							if('or' in rows[role][i]) {
								maxOr = rows[role][i]['or'];
							}
						}
						if(maxOr > 0) { rowSpan+=(maxOr-1); }
						return (
							<Fragment key={'role_'+i}>
								<Table.Row>
									<Table.Cell rowSpan={rowSpan}>{role}</Table.Cell>
									<Table.Cell><Link to={'/organisation/' + rows[role][0]._id}>{rows[role][0].name}</Link></Table.Cell>
									{'note' in rows[role][0] &&
										<Table.Cell>{rows[role][0].note}</Table.Cell>
									}
								</Table.Row>
								{rows[role].map(function(person, i) {
									if(i === 0) { return <Fragment key={'company_' + i}></Fragment>; }
									return <Table.Row>
										<Table.Cell><Link to={'/organisation/' + person._id}>{person.name}</Link></Table.Cell>
										{'note' in person &&
											<Table.Cell>{person.note}</Table.Cell>
										}
									</Table.Row>
								})}
							</Fragment>
						)
					})}
				</Table.Body>
				<Table.Footer fullWidth>
					{('sessions' in props && props.sessions.length > 1) && 
							<Table.Row><Table.HeaderCell colSpan={3}>
								{props.sessions.map(function(session, index) {
									return <><small key={index}>{'(' + (index+1) + ') ' + props.sessions[index].type + " session (" + props.sessions[index].obj.date_display + ")"}</small><br/></>
								})}
							</Table.HeaderCell></Table.Row>
					}
				</Table.Footer>
			</Table>;
	}
	return element;
}

import React, { useState } from 'react';
import { ReleaseBrowseCommon } from './../browse/ReleaseBrowseCommon';
import { Header, Divider, Icon } from 'semantic-ui-react'

export function RecordingTakeReleases(props) {
	const [childRendering, setChildRendering] = useState(true);
	return (
		<>
			{childRendering &&
				<>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='radio' />
							Releases
						</Header>
					</Divider>
					<ReleaseBrowseCommon 
						take={props.take} variants={props.variants} 
						event={props.event} event_variants={props.event_variants} 
						film={props.film} film_variants={props.film_variants} 
						radio={props.radio} radio_variants={props.radio_variants} 
						television={props.television} television_variants={props.television_variants} 
						advert={props.advert} advert_variants={props.advert_variants} 
						urlParams={false} relatedRendered={(val) => setChildRendering(val)} slim={true}/>
				</>
			}
		</>
	);
}
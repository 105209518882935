import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API_ADDRESS } from './../../constants.js';

import { Icon, Label, Table, Dimmer, Loader, Segment, Button, Modal, Grid, Form, Checkbox, Pagination, Input, Header } from 'semantic-ui-react'
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import useUrlState from '@ahooksjs/use-url-state';

export function RecordingBrowseCommon(props) {
	const navigate = useNavigate();
	const ref = React.useRef(null);

	const [firstLoad, setFirstLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [resetPage, setResetPage] = useState(false);
	const [changePage, setChangePage] = useState(false);
	const [searching, setSearching] = useState(false);
	const [rows, setRows] = useState([]);
	const [first, setFirst] = useState(0);
	const [last, setLast] = useState(0);
	const [showArtistFilterModal, setShowArtistFilterModal] = useState(false);

	let sortFieldDefault = "song";
	if(props.slim && !props.song) {
		sortFieldDefault = "date";
	}
	let stateTmp = {
		page: 1,
		sort: sortFieldDefault,
		query: "",
		filterArtists: [],
		filterTypes: [],
		filterStatus: []
	};

	const [numberOfRows] = useState(10);
	const [urlState, setUrlState] = useUrlState(stateTmp,
		{
			parseOptions: {
				arrayFormat: 'bracket',
			},
			stringifyOptions: {
				arrayFormat: 'bracket',
			},
		}
	);
	let stateToUse = props.slim ? stateTmp : urlState;
	const [state, setState] = useState(stateToUse);

	const [queryTmp, setQueryTmp] = useState(state.query);
	const [pages, setPages] = useState(state.page);
	const [sortByDate, setSortByDate] = useState(state.sort === 'date');
	const [sortBySong, setSortBySong] = useState(state.sort === 'song');

	const stateHelper = useCallback((stateTmp) => {
		let stateToSet = {};
		if(stateTmp.page !== undefined && Number(state.page) !== Number(stateTmp.page)) {
			stateToSet['page'] = Number(stateTmp.page);
		}
		for(const key in stateTmp) {
			if(key === 'page') { continue; }
			if(key === 'filterStatus' || key === 'filterTypes' || key === 'filterStatus') {
				if( !(stateTmp[key].length === state[key].length &&
				 stateTmp[key].every( function(this_i,i) { return this_i === state[key][i] } ) ) ) {
					stateToSet[key] = stateTmp[key];
				}
			} else {
				if(stateTmp[key] !== undefined && state[key] !== stateTmp[key]) {
					stateToSet[key] = stateTmp[key];
				}
			}
		}
		if(Object.keys(stateToSet).length > 0) {
			setState(prevState => ({...prevState, ...stateToSet}));
			if(!props.slim) {
				setUrlState(prevState => ({...prevState, ...stateToSet}));
			}
		}
	}, [state, setState, props.slim, setUrlState]);

	const filterHelper = (array, field) => {
		let arrayCopy = [];
		state[array].forEach(function(key) {
			if(key !== field) {
				arrayCopy.push(key);
			}
		});
		if(!state[array].includes(field)) {
			arrayCopy.push(field);
		}
		const tmp = {};
		tmp[array] = arrayCopy;
		stateHelper(tmp);
	};

	const search = useCallback(() => {
		let pagetmp = state.page;
		if( resetPage || Number(pagetmp) === 0 ) {
			pagetmp = 1;
		}
	
		var urlRoot = `${API_ADDRESS}/recording/browse`;
		var url = `?page=${pagetmp}`;
		var urlAttachment = '&sort=' + state.sort + '&limit=' + numberOfRows;
		if(state.query) {
			urlAttachment += '&query=' + encodeURIComponent(state.query);
		}
		if(props.event) {
			urlAttachment += '&event=' + props.event;
		}
		if(props.song) {
			urlAttachment += '&song=' + props.song;
		}
		if(props.person) {
			urlAttachment += '&person=' + props.person;
		}
		if(state.filterArtists) {
			state.filterArtists.forEach(function(filter) {
				urlAttachment += '&filterArtists=' + filter;
			});
		}
		if(state.filterTypes) {
			state.filterTypes.forEach(function(filter) {
				urlAttachment += '&filterTypes=' + filter;
			});
		}
		if(state.filterStatus) {
			state.filterStatus.forEach(function(filter) {
				urlAttachment += '&filterStatus=' + filter;
			});
		}

		fetch(urlRoot + url + urlAttachment)
			.then(function(response) {
				return response.json()
			})
			.then(function(json) {
				setTotal(json.count);
				setResetPage(false);
				stateHelper({page: json.page});
				setPages(json.pages);
				setLoading(false);
				setChangePage(false);

				var rows = json.recordings;
				setRows(rows);

				var first_tmp = (json.page-1)*numberOfRows+1;
				var last_tmp = (json.page)*numberOfRows;
				if( first_tmp > json.count ) {
					first_tmp = 0;
					last_tmp = 0;
				}
				if( last_tmp > json.count ) {
					last_tmp = json.count;
				}
				setFirst(first_tmp);
				setLast(last_tmp);

				setSearching(false);
				if(!firstLoad) {
					ref.current.scrollIntoView({behavior: 'smooth'});
				}

				if(firstLoad && rows.length === 0 && props.relatedRendered) {
					props.relatedRendered(false);
				}
				setFirstLoad(false);
			})
			.catch(function(ex) {
				setSearching(false);
				console.log(ex);
				navigate('/500');
			})
	}, [numberOfRows, state, stateHelper, resetPage, navigate, props.event, props.song, props.person]);	

	const handlePaginationClick = (e, { activePage }) => stateHelper({page: activePage});
	const changeSortField = (field) => stateHelper({sort:field});

	useEffect(() => { 
		if(!props.slim) {
			let stateToSet = {};
			for(const key in urlState) {
				if(key === 'page') {
					if(urlState.page !== undefined && Number(state.page) !== Number(urlState.page)) {
						stateToSet['page'] = Number(urlState.page);
					}
				} else {
					if(urlState[key] !== undefined && state[key] !== urlState[key]) {
						stateToSet[key] = urlState[key];
					}
				}
			}
			if(Object.keys(stateToSet).length > 0) {
				setState(prevState => ({...prevState, ...stateToSet}));
				if(stateToSet.query) {
					setQueryTmp(stateToSet.query);
					setLoading(true);
				}
			}
		}
	}, [urlState, state]);

	useEffect(() => { if( loading && !searching ) { setSearching(true); search() } }, [loading, search, searching])
	useEffect(() => { if( changePage ) { setLoading(true); } }, [changePage])
	useEffect(() => { setChangePage(true) }, [state.page, state.sort]);
	useEffect(() => { 
		if(state.sort === 'song') { 
			setSortBySong(true); 
			setSortByDate(false);
		} else { 
			setSortBySong(false); 
			setSortByDate(true);
		} 
		setResetPage(true);
		setChangePage(true);
	}, [state.sort])

	const clearArtistFilters = () => {
		setResetPage(true);
		setChangePage(true);
		stateHelper({filterArtists:[], filterTypes: [], filterStatus: []});
		setShowArtistFilterModal(false);
	}
	const runArtistFilters = () => {
		setResetPage(true);
		setChangePage(true);
		setShowArtistFilterModal(false);
	}

	const handleClick = (event, recording) => {
		if (event.ctrlKey) {
			window.open(`/recording/${recording}`, "_blank")
		} else {
			navigate(`/recording/${recording}`)
		}
	}

	var filterArtistsText = '';
	state.filterArtists.forEach(function(key) {
		if(filterArtistsText !== '') { filterArtistsText += ', '; }
		if(key === 'childrens_chorus') {
			key = "children's chorus";
		}
		filterArtistsText += key.charAt(0).toUpperCase() + key.slice(1).replaceAll('_', ' ');
	});
	state.filterTypes.forEach(function(key) {
		if(filterArtistsText !== '') { filterArtistsText += ', '; }
		filterArtistsText += key;
	});
	state.filterStatus.forEach(function(key) {
		if(filterArtistsText !== '') { filterArtistsText += ', '; }
		filterArtistsText += key.charAt(0).toUpperCase() + key.slice(1).replaceAll('_', ' ');
	});
	
	let className = "default_height_500 browse-table-segment";
	if(props.slim) {
		className = "merchandise_open browse-table-segment";
	}

	return (
		<div ref={ref}>
			<Segment inverted className={className}>
				<Dimmer active={loading}>
					<Loader size='large'>Loading</Loader>
				</Dimmer>
				{!props.slim &&
					<Grid columns={3} stackable relaxed='very' className="width100">
						<Grid.Column>
							<Button.Group>
								<Button positive={sortByDate} onClick={() => changeSortField('date')}>Sort by Date</Button>
								<Button.Or />
								<Button positive={sortBySong} onClick={() => changeSortField('song')}>Sort by Song</Button>
							</Button.Group>
						</Grid.Column>
						<Grid.Column>
							<Input inverted fluid
								action={{ icon: 'search', onClick: (e) => {setResetPage(true); setChangePage(true);} }}  
								placeholder='Search...'
								value={queryTmp}
								onChange={(e, { value }) => setQueryTmp(value)}
								onBlur={(e) => stateHelper({query:queryTmp})}
								onKeyUp={(e) => {if (e.key === "Enter") {stateHelper({query:queryTmp}); setResetPage(true); setChangePage(true);}}}
							/>
						</Grid.Column>
						<Grid.Column>
							<Button onClick={() => setShowArtistFilterModal(true)}>
								Advance Filters
							</Button>
							{filterArtistsText && <p><i>Filters active: {filterArtistsText}</i></p>}
						</Grid.Column>
					</Grid>
				}
				<Table celled inverted selectable>
					<Table.Header>
						<Table.Row className='browse-table-header'>
							<Table.HeaderCell>Song</Table.HeaderCell>
							<Table.HeaderCell>Artist</Table.HeaderCell>
							<Table.HeaderCell>Date</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>Status</Table.HeaderCell>
							<Table.HeaderCell>View</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{rows.length === 0 && 
								<Table.Row>
									<Table.Cell colSpan={7}>
										<Segment placeholder inverted>
											<Header icon inverted>
												<Icon name='search' />
												No recordings match your query
											</Header>
										</Segment>
									</Table.Cell>
								</Table.Row>
						}
						{rows.map(function(row){
							var headerRow;
							if(row.header_type === 'event') {
								headerRow = <Table.Row>
									<Table.Cell colSpan={5}>
										<Label ribbon>{row.header_date_display}{'header_date_timing' in row && <> ({row.header_date_timing})</>}{'header_location' in row && <> - {row.header_location}</>}</Label>
									</Table.Cell>
								</Table.Row>
							} else {
								headerRow = <Table.Row>
									<Table.Cell colSpan={5}>
										<Label ribbon>{row.header_title}</Label>
									</Table.Cell>
								</Table.Row>
							}
							return <Fragment key={row.header_id}>
								{(!props.song && !props.event) &&
									headerRow
								}
								{row.recordings.map(function(recording){
									var rows = [];
									if(recording.take_name) {
										var takes = [];
										recording.take_name.map(function(take, i) {
											let pos = i;
											if('take_position' in recording) {
												pos = recording.take_position[i];
											}
											if(take.toLowerCase() !== recording['take_type'][i].toLowerCase()) {
												take += ' (' + recording['take_type'][i].toLowerCase() + ')';
											}
											var key = recording.id + '_' + recording['take_id'][i];
											var status = [];
											if(recording['recording-audio'][i]) {
												status.push(<p key={key + '_audio'}><Icon name='sound' title='Audio recording in-circulation'/></p>);
											}
											if(recording['recording-video'][i]) {
												status.push(<p key={key + '_video'}><Icon name='video' title='Video recording in-circulation'/></p>);
											}
											if(recording['recording-unreleased'][i]) {
												status.push(<p key={key + '_unreleased'}><Icon name='lock' title='Recording unreleased'/></p>);
											}
											if(recording['recording-no_recording'][i]) {
												status.push(<p key={key + '_no'}><Icon name='close' title='No known recording'/></p>);
											}
											if(recording['variant_count'][i]) {
												status.push(<p key={key + '_variant'}><Icon name='cut' title='Variants and Excerpts'/>{recording['variant_count'][i]}</p>);
											}
											if(recording['release_count'][i]) {
												status.push(<p key={key + '_release'}><Icon name='radio' title='Releases'/>{recording['release_count'][i]}</p>);
											}
											takes.push({
												id: recording['take_id'][i],
												pos: pos,
												take: take,
												status: status
											});
											return <></>;
										});
										takes.sort((a,b) => { return a.pos === b.pos ? 0 : a.pos < b.pos ? -1 : 1 });
										takes.map(function(take, i) {
											var key = recording.id + '_' + take.id;
											if(i === 0) {
												rows.push(<Table.Row key={key} onClick={(event) => handleClick(event, recording.recording_id)} className='browse-table-row'>
															<Table.Cell rowSpan={recording.take_name.length}>{recording.name}</Table.Cell>
															<Table.Cell rowSpan={recording.take_name.length} className="artist-cell">{recording.artist}</Table.Cell>
															<Table.Cell rowSpan={recording.take_name.length}>{recording.date_display}</Table.Cell>
															<Table.Cell>{take.take}</Table.Cell>
															<Table.Cell>{take.status}</Table.Cell>
															<Table.Cell rowSpan={takes.length}>
																<Link to={'/recording/' + recording.recording_id}>view</Link>
															</Table.Cell>
														</Table.Row>);
											} else {
												rows.push(<Table.Row key={key} onClick={(event) => handleClick(event, recording.recording_id)}	 className='browse-table-row'>
															<Table.Cell>{take.take}</Table.Cell>
															<Table.Cell>{take.status}</Table.Cell>
														</Table.Row>);
											}
											return <></>;
										});
									}
									return rows;
								})}
							</Fragment>
						})}
					</Table.Body>
					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell>
								Showing {first}-{last} of {total} {(props.song || props.event) && 'recording'}{(!props.song && !props.event) && state.sort}s.
							</Table.HeaderCell>
						<Table.HeaderCell colSpan='5' textAlign='right'>
							<Pagination activePage={state.page} totalPages={pages} firstItem={null} lastItem={null} onPageChange={handlePaginationClick} siblingRange={1} inverted/>
						</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>

			{!props.slim &&
				<Modal
					dimmer='blurring'
					open={showArtistFilterModal}
					onClose={() => runArtistFilters()}
				>
					<Modal.Header>Advanced Filters</Modal.Header>
					<Modal.Content>
						<Form>
							<Form.Group grouped>
								<Grid columns={3} stackable divided>
									<Grid.Row>
										<b>Filter by Artist and Accompaniment types</b>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Vocals' }}
												checked={state.filterArtists.includes('vocals')}
												onChange={(e, { checked }) => filterHelper('filterArtits', 'vocals')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Vocal group' }}
												checked={state.filterArtists.includes('vocal_group')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'vocal_group')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Vocal chorus' }}
												checked={state.filterArtists.includes('vocal_chorus')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'vocal_chorus')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Children\'s chorus' }}
												checked={state.filterArtists.includes('childrens_chorus')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'childrens_chorus')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Duet' }}
												checked={state.filterArtists.includes('duet')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'duet')}
											/>
										</Grid.Column>
										<Grid.Column>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Big Band' }}
												checked={state.filterArtists.includes('big_band')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'big_band')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Orchestra' }}
												checked={state.filterArtists.includes('orchestra')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'orchestra')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Strings' }}
												checked={state.filterArtists.includes('strings')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'strings')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Ensemble' }}
												checked={state.filterArtists.includes('ensemble')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'ensemble')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Solo accompaniment' }}
												checked={state.filterArtists.includes('solo_accompaniment')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'solo_accompaniment')}
											/>
										</Grid.Column>
										<Grid.Column>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Acapella' }}
												checked={state.filterArtists.includes('acapella')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'acapella')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Instrumental' }}
												checked={state.filterArtists.includes('instrumental')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'instrumental')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Spoken' }}
												checked={state.filterArtists.includes('spoken')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'spoken')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Remix' }}
												checked={state.filterArtists.includes('remix')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'remix')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Electronic' }}
												checked={state.filterArtists.includes('electronic')}
												onChange={(e, { checked }) => filterHelper('filterArtists', 'electronic')}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<b>Filter by performance type</b>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Studio master' }}
												checked={state.filterTypes.includes('Studio master')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Studio master')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Concert' }}
												checked={state.filterTypes.includes('Concert')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Concert')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Radio' }}
												checked={state.filterTypes.includes('Radio')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Radio')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'V-Disc' }}
												checked={state.filterTypes.includes('V-Disc')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'V-Disc')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Television' }}
												checked={state.filterTypes.includes('Television')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Television')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Film' }}
												checked={state.filterTypes.includes('Film')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Film')}
											/>
										</Grid.Column>
										<Grid.Column>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Alternate take' }}
												checked={state.filterTypes.includes('Alternate take')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Alternate take')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Session material' }}
												checked={state.filterTypes.includes('Session material')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Session material')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Incomplete take' }}
												checked={state.filterTypes.includes('Incomplete take')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Incomplete take')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'False start' }}
												checked={state.filterTypes.includes('False start')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'False start')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Rehearsal' }}
												checked={state.filterTypes.includes('Rehearsal')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Rehearsal')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Demo recording' }}
												checked={state.filterTypes.includes('Demo recording')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Demo recording')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Orchestra track' }}
												checked={state.filterTypes.includes('Orchestra track')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Orchestra track')}
											/>
										</Grid.Column>
										<Grid.Column>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Orchestra overdub' }}
												checked={state.filterTypes.includes('Orchestra overdub')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Orchestra overdub')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Remix' }}
												checked={state.filterTypes.includes('Remix')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Remix')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Composite' }}
												checked={state.filterTypes.includes('Composite')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Composite')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Edit' }}
												checked={state.filterTypes.includes('Edit')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Edit')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Song extract' }}
												checked={state.filterTypes.includes('Song extract')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Song extract')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Excerpt' }}
												checked={state.filterTypes.includes('Excerpt')}
												onChange={(e, { checked }) => filterHelper('filterTypes', 'Excerpt')}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<b>Filter by release status</b>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Audio' }}
												checked={state.filterStatus.includes('audio')}
												onChange={(e, { checked }) => filterHelper('filterStatus', 'audio')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Video' }}
												checked={state.filterStatus.includes('video')}
												onChange={(e, { checked }) => filterHelper('filterStatus', 'video')}
											/>
										</Grid.Column>
										<Grid.Column>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Unreleased' }}
												checked={state.filterStatus.includes('unreleased')}
												onChange={(e, { checked }) => filterHelper('filterStatus', 'unreleased')}
											/>
											<Form.Field
												control={Checkbox}
												label={{ children: 'No recording' }}
												checked={state.filterStatus.includes('no_recording')}
												onChange={(e, { checked }) => filterHelper('filterStatus', 'no_recording')}
											/>
										</Grid.Column>
										<Grid.Column>
											<Form.Field
												control={Checkbox}
												label={{ children: 'Sinatraology collection' }}
												checked={state.filterStatus.includes('sinatraology_collection')}
												onChange={(e, { checked }) => filterHelper('filterStatus', 'sinatraology_collection')}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<i>More filters coming soon</i>
									</Grid.Row>
								</Grid>
							</Form.Group>
						</Form>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => clearArtistFilters()}>
							Reset filters
						</Button>
						<Button positive onClick={() => runArtistFilters()}>
							Filter
						</Button>
					</Modal.Actions>
				</Modal>
			}
		</div>
	);
}
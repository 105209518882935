import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from "react-router";
import { API_ADDRESS, PAGE_TITLE_BASE } from './../constants.js';
import { Dimmer, Loader, Segment, Header, Grid, Table, Divider, Icon, Tab, Message, TableRow } from 'semantic-ui-react'
import { Link } from "react-router-dom";

export function InformationPlease(props) {
	document.title = 'Information Please | ' + PAGE_TITLE_BASE;

	const [loading, setLoading] = useState(true);
	const [releases, setReleases] = useState([]);
	const [recordings, setRecordings] = useState([]);
	const [tabIndex, setTabIndex] = useState(0);
	const navigate = useNavigate();

	const search = useCallback(() => {
		fetch(`${API_ADDRESS}/information_please/browse`)
			.then(function(response) {
				return response.json()
			}).then(function(json) {
				if( json.length === 0 ) {
					navigate('/404');
				}
				console.log(json);
				setReleases(json.releases);
				setRecordings(json.recordings);
				setLoading(false);
			}).catch(function(ex) {
				console.log(ex);
				navigate('/500');
			})
	}, [navigate]);

	useEffect(() => { if( loading ) { search() } }, [loading, search])

	let tabs = [];
	var release_tab = {
		menuItem: 'Releases',
		render: () => <Tab.Pane>
			<Table definition inverted>
				<Table.Body>
					<TableRow>
						<Table.HeaderCell width={2}>Title</Table.HeaderCell>
						<Table.HeaderCell width={2}>Link</Table.HeaderCell>
						<Table.HeaderCell width={4}>Information Wanted</Table.HeaderCell>
					</TableRow>
					{releases.map(function(release) {
						return <Table.Row key={release._id}>
							<Table.Cell>{release.title}</Table.Cell> 
							<Table.Cell><Link to={'/release/' + release._id}>Link</Link></Table.Cell>
							<Table.Cell>
								<ul>
									{release.info_wanted.map(function(track) {
										return <li>{track.disc_name} track {track.position} - {track.name}</li>
									})}
								</ul>
							</Table.Cell> 
						</Table.Row>
					})}
				</Table.Body>
			</Table>
		</Tab.Pane>
	};
	tabs.push(release_tab);
	var recording_tab = {
		menuItem: 'Recording',
		render: () => <Tab.Pane>
			<Table definition inverted>
				<Table.Body>
					<TableRow>
						<Table.HeaderCell width={2}>Title</Table.HeaderCell>
						<Table.HeaderCell width={2}>Link</Table.HeaderCell>
						<Table.HeaderCell width={4}>Information Wanted</Table.HeaderCell>
					</TableRow>
					{recordings.map(function(recording) {
						return <Table.Row key={recording._id}>
							<Table.Cell>{recording.title}</Table.Cell> 
							<Table.Cell><Link to={'/recording/' + recording._id}>Link</Link></Table.Cell>
							<Table.Cell>{recording.info_wanted}</Table.Cell> 
						</Table.Row>
					})}
				</Table.Body>
			</Table>
		</Tab.Pane>
	};
	tabs.push(recording_tab);

	return (
		<Segment inverted style={{minHeight:'500px', margin:'1%'}}>
			<Dimmer active={loading}>
				<Loader size='large'>Loading</Loader>
			</Dimmer>
			<Grid doubling columns={2}>
				<Grid.Row>
					<Header as='h1' inverted style={{textAlign:'center'}}>Information Please</Header>
				</Grid.Row>
				<Grid.Row>
					<Message negative icon>
						<Message.Header><Icon name='help'/> Information Wanted</Message.Header>
						<Message.Content>
							We need more information to determine the recording dates for the following tracks:<br/>
							If you have any information, please contact us at <a href="contact:admin@sinatraology.com">admin@sinatraology.com</a>.
						</Message.Content>
					</Message>
				</Grid.Row>
				<Grid.Row>
					<Tab panes={tabs} menu={{ inverted: true, attached: true, tabular: true }} activeIndex={tabIndex} onTabChange={(event, data) => setTabIndex(data.activeIndex)}/>
				</Grid.Row>
			</Grid>
		</Segment>
	);}
